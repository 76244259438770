import { createTheme } from '@mui/material';
import '@fontsource/dm-sans/400.css';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/700.css';

export const palette = {
	primary: {
		gold001: '#fbf5e8',
		gold002: '#DC9733',
		gold003: '#FBF4EA',
	},
	base: {
		white: '#FFFFFF',
		white001: '#FEFEFE',

		gray001: '#F5F5F5',
		gray002: '#D9D9D9',
		gray003: '#A4A1A1',

		black001: '#393939',
		black002: '#151515',
		black: '#000000',

		background: '#f8f8f8',
	},
	default: {
		error: '#E13737',
		warning: '#FDB913',
		success: '#1CAB44',
		link: '#3A61C5',
		inactive: '#919092',
	},
};

export const typography = {
	fontSize: {
		xs: 12,
		small: 14,
		base: 18,
		h1: 32,
		h2: 21,
		h3: 18,
	},
	fontWeight: {
		r: '400',
		m: '500',
		bold: '700',
	},
	lineHeight: {
		xs: '18px',
		small: '20px',
		base: '20px',
		h1: '40px',
	},
};

const shadow = {
	s01: '0px 2px 8px rgba(117, 131, 142, 0.04), 0px 16px 24px rgba(52, 60, 68, 0.12)',
	s02: '0px 2px 8px rgba(117, 131, 142, 0.08), 0px 20px 32px rgba(52, 60, 68, 0.16)',
	s03: '0px 0px 2px rgba(117, 131, 142, 0.04), 0px 4px 8px rgba(52, 60, 68, 0.16)',
};

const theme = createTheme({
	shape: {
		br1: '4px',
		br2: '8px',
	},
	palette: {
		gold001: palette.primary.gold001,
		gold002: palette.primary.gold002,
		gold003: palette.primary.gold003,

		gray001: palette.base.gray001,
		gray002: palette.base.gray002,
		gray003: palette.base.gray003,

		base: {
			white: palette.base.white,
			white001: palette.base.white001,
			black: palette.base.black,
			black001: palette.base.black001,
			black002: palette.base.black002,

			background: palette.base.background,
		},
		default: {
			error: palette.default.error,
			warning: palette.default.warning,
			success: palette.default.success,
			link: palette.default.link,
			inactive: palette.default.inactive,
		},
		text: {
			primary: palette.base.black002,
		},
	},
	shadow: {
		s01: shadow.s01,
		s02: shadow.s02,
		s03: shadow.s03,
	},
	typography: {
		fontFamily: 'DM Sans, sans-serif',
		fontSize: {
			xs: typography.fontSize.xs,
			small: typography.fontSize.small,
			base: typography.fontSize.base,
			h1: typography.fontSize.h1,
			h2: typography.fontSize.h2,
			h3: typography.fontSize.h3,
		},
		fontWeight: {
			r: '400',
			m: '500',
			bold: '700',
		},
		lineHeight: {
			xs: '18px',
			small: '20px',
			base: '24px',
			h1: '40px',
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					color: palette.base.black001,
					fontWeight: '400',
					fontSize: typography.fontSize.small,
					lineHeight: '20px',
					textAlign: 'left',
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					width: '70px',
					height: '50px',
				},
				switchBase: {
					top: '4px',
					left: '4px',
				},
				thumb: {
					boxShadow: 'none',
					top: '5px',
					left: '5px',
					width: '24px',
					height: '24px',
				},
				track: {
					borderRadius: 100,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: palette.base.black002,
					fontSize: typography.fontSize.small,
					fontWeight: '700',
					'&.Mui-focused, &.Mui-error': {
						color: palette.base.black002,
					},
					transform: 'unset',
				},
				asterisk: {
					color: palette.default.error,
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					fontSize: typography.fontSize.xs,
					fontWeight: '400',
					color: palette.default.error,
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					marginTop: '21px !important',
					borderRadius: 8,
					fontSize: typography.fontSize.small,
					fontWeight: typography.fontWeight.r,
					backgroundColor: 'transparent',
					border: `1px solid ${palette.base.gray003}`,
					'&.Mui-error': {
						border: `1px solid ${palette.default.error}`,
					},
					'&.Mui-focused': {
						// width: 'calc(100% + 2px)',
						// input: {
						// 	padding: '10px 10px 10px 14px',
						// },
					},
					'&:hover': {
						// width: 'calc(100% + 2px)',
						// border: `2px solid ${palette.gold003}`,
						// input: {
						// 	padding: '10px 10px 10px 14px',
						// },
					},
					'&.Mui-disabled': {
						// border: 'none',
						input: {
							// padding: '12px 12px 12px 16px',
						},
					},
					'&:before': {
						display: 'none',
					},
					'&:after': {
						display: 'none',
					},
				},
				input: {
					borderRadius: 8,
					padding: '14px 14px 14px 16px',
					color: palette.base.black002,
					lineHeight: typography.lineHeight.base,
					// 'caretColor': palette.gold003,
					'::placeholder': {
						color: 'palette.base.gray003',
					},
				},
			},
		},
		MuiCalendarPicker: {
			styleOverrides: {
				root: {
					'.MuiDayPicker-weekContainer': {
						'.Mui-selected': {
							backgroundColor: `${palette.primary.gold002}!important`,
						},
					},
				},
			},
		},
	},
});

export default theme;
