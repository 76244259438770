import React from 'react';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { Checkbox as CheckboxComponent, Radio, FormControlLabel } from '@mui/material';
import { CheckedIcon } from 'assets/icons';

export const Checkbox = ({ field, label, color, colorChecked, onChange, disabled, radio, ...props }) => {
	const theme = useTheme();
	const InputComponent = radio ? Radio : CheckboxComponent;

	return (
		<FormControlLabel
			control={
				<InputComponent
					checkedIcon={
						radio ? undefined : (
							<CheckedIcon color={colorChecked || theme.palette.base.black002} checked={true} sx={{ fontSize: '18px' }} />
						)
					}
					icon={
						radio ? undefined : (
							<CheckedIcon color={color || theme.palette.base.black002} checked={false} sx={{ fontSize: '18px' }} />
						)
					}
					checked={field.value}
					disabled={disabled}
					{...field}
				/>
			}
			label={label}
			{...props}
		/>
	);
};

Checkbox.propTypes = {
	label: PropTypes.node,
	field: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	radio: PropTypes.bool,
	color: PropTypes.string,
};
