import React, { useState } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { IconButton, MenuItem, Badge } from '@mui/material';
import { Dropdown } from './Dropdown';
import { EnglandIcon, LithuaniaIcon } from 'assets/icons';

const StyledBadge = styled(Badge)(({ theme }) => ({
	alignItems: 'center',
	'& .MuiBadge-badge': {
		backgroundColor: theme.palette.gray002,
		minWidth: 16,
		height: 16,
		padding: 0,
		right: 4,
		bottom: 2,
	},
}));
const Button = styled(IconButton)(({ theme }) => ({
	width: 40,
	height: 40,
}));
const Label = styled('p')(() => ({
	margin: 0,
	textTransform: 'uppercase',
}));

const LanguageSelect = () => {
	const { i18n } = useTranslation();
	const language = localStorage.getItem('i18nextLng');

	const languages = [
		{ key: 'en', label: 'English', icon: EnglandIcon },
		{ key: 'lt', label: 'Lithuanian', icon: LithuaniaIcon },
	];

	const [close, setClose] = useState(false);

	const handleSelect = event => {
		const { myValue } = event.currentTarget.dataset;
		setClose(!close);
		i18n.changeLanguage(myValue);
	};

	return (
		<Dropdown
			button={
				<StyledBadge
					// badgeContent={<PolygonIcon sx={{ fontSize: 6 }} />}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					sx={{ pt: 1, pb: 1 }}>
					<Button>
						<Label>{language}</Label>
					</Button>
				</StyledBadge>
			}
			menuContent={languages.map(lang => (
				<MenuItem key={lang.key} onClick={handleSelect} data-my-value={lang.key}>
					<img src={lang.icon} alt="" width="24" height="24" />
				</MenuItem>
			))}
			onClose={close}
			sx={{ mr: 2 }}
		/>
	);
};

export default LanguageSelect;
