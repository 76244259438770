import api from 'api/axios';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import React, { useEffect } from 'react';
import { AppSpinner } from 'components';
import { useLocation, useNavigate, Navigate, Outlet } from 'react-router-dom';

export const RequireLogin = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { auth, setAuth } = useAuth();
	const { setUser } = useUser();

	const verifySession = async () => {
		try {
			const res = await api.get('/session');
			setAuth({ user: res.data.user });

			const resUser = await api.get('/user');
			const { firstName, lastName } = resUser.data.user;
			setUser({ name: `${firstName} ${lastName}`, ...resUser.data.user });
		} catch (error) {
			navigate('/login', { state: { from: location } });
		}
	};

	useEffect(() => {
		if (!auth?.user) {
			verifySession();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');

	if (!auth.user) {
		return <AppSpinner />;
	}

	return token ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
};
