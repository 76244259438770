import React from 'react';
import { SvgIcon } from '@mui/material';

function BookIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 21 21" fill="none">
			<path
				d="M1.75 2.625H7C7.92826 2.625 8.8185 2.99375 9.47487 3.65013C10.1313 4.3065 10.5 5.19674 10.5 6.125V18.375C10.5 17.6788 10.2234 17.0111 9.73115 16.5188C9.23887 16.0266 8.57119 15.75 7.875 15.75H1.75V2.625Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M19.25 2.625H14C13.0717 2.625 12.1815 2.99375 11.5251 3.65013C10.8687 4.3065 10.5 5.19674 10.5 6.125V18.375C10.5 17.6788 10.7766 17.0111 11.2688 16.5188C11.7611 16.0266 12.4288 15.75 13.125 15.75H19.25V2.625Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default BookIcon;
