import api from 'api/axios';
import moment from 'moment';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { schema } from './schema';
import { showToast } from 'utils/utils';
import { Formik, Form, Field } from 'formik';
import { Modal, Button, Select, InputUpload, Input, InputDatePicker } from 'components';
import { Box, Grid } from '@mui/material';

const GroupLabel = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));

const initialValues = {
	title: '',
	type: '',
	endDate: '',
	description: '',
};

export const CreateService = ({ title, open, onClose, onSuccess }) => {
	const { t } = useTranslation();
	const today = moment().format('YYYY-MM-DD');

	const [files, setFiles] = useState([]);
	const [endDate, setEndDate] = useState({ date: today });

	const createService = async values => {
		try {
			const { data } = await api.post('/orders', {
				...values,
				endDate: endDate.date,
			});
			files?.length > 0 &&
				(await api.post(
					`/client/documents/order/${data.id}`,
					{ document: files },
					{ headers: { 'Content-Type': 'multipart/form-data' } },
				));
			setEndDate({ date: today });
			onSuccess();
			onClose();
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const handleSubmit = async values => {
		createService(values);
	};

	const handleClose = () => {
		setEndDate({ date: today });
		onClose();
	};

	const handleUpload = files => {
		setFiles(files);
	};

	return (
		<Modal title={title} open={open} onClose={handleClose} width="40%">
			<Formik initialValues={initialValues} validationSchema={Yup.object(schema)} onSubmit={handleSubmit}>
				{({ values, setFieldValue, touched, errors, setErrors }) => (
					<Form>
						<Grid container>
							<Field
								id="title"
								name="title"
								component={Input}
								label={t('Title')}
								placeholder={t('Title')}
								errors={errors}
								touched={touched}
								value={values.title}
								fullWidth
								type="text"
								variant="standard"
								InputLabelProps={{
									shrink: true,
									required: true,
								}}
								sx={{ mt: 4 }}
							/>
							<Field
								id="type"
								name="type"
								component={Select}
								label={t('Type')}
								placeholder={t('Type')}
								value={values.type}
								fullWidth
								options={[{ label: 'Type', value: 'type' }]}
								InputLabelProps={{
									required: true,
								}}
								sx={{ mt: 2, width: '100%' }}
							/>
							<InputDatePicker
								initDate={endDate.date}
								hideTime
								onChange={date => setEndDate(date)}
								label={t('EndDate')}
								InputLabelProps={{ required: true }}
								sx={{ mt: 2, width: '100%' }}
							/>
							<Field
								id="description"
								name="description"
								component={Input}
								label={t('Description')}
								placeholder={t('Description')}
								errors={errors}
								touched={touched}
								value={values.description}
								fullWidth
								type="text"
								multiline
								rows={5}
								variant="standard"
								InputLabelProps={{
									shrink: true,
									required: true,
								}}
								sx={{ mt: 2 }}
							/>
							<Grid item xs={12} sx={{ mt: 2 }}>
								<GroupLabel>{t('Documents')}</GroupLabel>
								<InputUpload
									onChange={handleUpload}
									multiple
									allowRemove
									text={t('UploadDocumentDocuments')}
									sx={{ '.drop-area': { height: '80px', border: '2px dashed #DDDDDD' } }}
								/>
							</Grid>
						</Grid>
						<Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
							<Button type="submit" sx={{ mt: 5 }} disabled={!values.title || !values.type || !values.description}>
								{t('Send')}
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};
