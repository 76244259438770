import React from 'react';
import PropTypes from 'prop-types';
import imgWeb from './brandImage1.png';
import imgMobile from './brandImage2.png';

function BrandImage(props) {
	return (
		<img src={props.mobile ? imgMobile : imgWeb} width={props.width} height={props.height} alt="" />
	);
}

BrandImage.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string,
};

export default BrandImage;
