import React, { useState, useMemo } from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDetails } from 'utils/utils';
import { BasicTable, MenuDropdown } from 'components';
import { DEFAULT_PAGE_SIZE } from 'constants/general';
import { TableCell, TableRow, MenuItem } from '@mui/material';
import { debounce } from 'lodash';
import qs from 'query-string';

const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.h2,
	fontWeight: theme.typography.fontWeight.bold,
}));

export const AccountantClients = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [companyName, setCompanyName] = useState('');
	const [companyCode, setCompanyCode] = useState('');
	const [email, setEmail] = useState('');

	const query = qs.stringify({ page, pageSize: DEFAULT_PAGE_SIZE, companyName, companyCode, email });
	const { details: clients } = useDetails(`/clients/list?${query}`, [], [page, companyName, companyCode, email]);

	const handlePageChange = (e, page) => {
		setPage(page);
	};
	const handleClick = item => {
		navigate('/clients/' + item.id);
	};

	const companyNameSearch = useMemo(() => debounce(e => setCompanyName(e.target.value), 500), []);
	const companyCodeSearch = useMemo(() => debounce(e => setCompanyCode(e.target.value), 500), []);
	const emailSearch = useMemo(() => debounce(e => setEmail(e.target.value), 500), []);

	const filters = [
		{ type: 'search', name: 'companyName', onChange: companyNameSearch },
		{ type: 'search', name: 'companyCode', onChange: companyCodeSearch },
		{ type: 'search', name: 'email', onChange: emailSearch },
		{ type: 'none' },
	];

	return (
		<>
			<Title sx={{ mt: 3, ml: 1 }}>{t('Clients')}</Title>

			<BasicTable
				sx={{ mt: 5 }}
				head={[t('ClientTitle'), t('CompanyCode'), t('Email'), t('Actions')]}
				filters={filters}
				page={page}
				pageCount={clients?.pageCount}
				onPageChange={handlePageChange}>
				{clients?.data?.map(item => (
					<TableRow key={item.id}>
						<TableCell onClick={() => handleClick(item)}>{item.companyName}</TableCell>
						<TableCell onClick={() => handleClick(item)}>{item.companyCode}</TableCell>
						<TableCell>{item.email}</TableCell>
						<TableCell align="right">
							<MenuDropdown>
								<MenuItem onClick={() => handleClick(item)}>{t('Preview')}</MenuItem>
							</MenuDropdown>
						</TableCell>
					</TableRow>
				))}
			</BasicTable>
		</>
	);
};
