import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { Select } from 'components';

export const FilterSelect = ({ options, field, optionLabel, optionValue }) => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Select
			placeholder={t('Select')}
			options={options}
			field={field}
			optionLabel={optionLabel}
			optionValue={optionValue}
			fullWidth
			style={{
				'&:before': {
					fontSize: theme.typography.fontSize.xs,
					fontWeight: theme.typography.fontWeight.bold,
					padding: '6px 14px 6px 16px',
				},
				'& .MuiInputBase-input': {
					borderRadius: 28,
					minWidth: 50,
					backgroundColor: theme.palette.base.white001,
					fontSize: theme.typography.fontSize.xs,
					fontWeight: theme.typography.fontWeight.bold,
					padding: '6px 14px 6px 16px',
					border: 'none',
					'&:focus': {
						borderRadius: 28,
					},
				},
			}}
		/>
	);
};

FilterSelect.propTypes = {};
