import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { defaultDateFormat } from 'utils/utils';
import { Grid, Box, Divider } from '@mui/material';
import { CreateTask, SelectChip } from 'components';
import { STATE, STATE_OPTIONS } from 'constants/general';

const Container = styled('div')(({ theme }) => ({
	borderRadius: 8,
	padding: '24px 16px',
	backgroundColor: theme.palette.base.white001,
}));
const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.base,
	fontWeight: theme.typography.fontWeight.bold,
}));
const CategoryTitle = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const TitleGray = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
}));
const Text = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.r,
}));

export const ServiceCard = ({ service = {}, onStateEdit }) => {
	const { t } = useTranslation();
	const [openTask, setOpenTask] = useState(false);

	const state = service?.state;

	return (
		<>
			<Container>
				{/* <Stack direction="row" justifyContent="flex-end" sx={{ height: '15px' }}>
					<ButtonBase sx={{ padding: '5px', position: 'absolute' }}>
						<EditIcon sx={{ color: theme.palette.gray003, fontSize: '14px' }} />
					</ButtonBase>
				</Stack> */}
				<Grid container spacing={1} alignItems="center">
					<Grid item xs={9} md={3}>
						<Title>{service.title}</Title>
					</Grid>
					<Grid item xs={3} md={3}>
						<SelectChip
							options={STATE_OPTIONS}
							field={{ value: state || STATE.NEW, onChange: e => onStateEdit(e.target.value) }}
						/>
					</Grid>
					<Grid item xs={6} md={3}>
						<Box>
							<TitleGray>{t('EndTime')}</TitleGray>
							<Text>{defaultDateFormat(service.endDate)}</Text>
						</Box>
					</Grid>
					<Grid item xs={6} md={3}>
						<TitleGray>{t('Type')}</TitleGray>
						{service.type}
					</Grid>

					<Grid item xs={12}>
						<Divider sx={{ my: 2 }} />
						<CategoryTitle>{t('Description')}</CategoryTitle>
						<Text>{service.description}</Text>
					</Grid>
				</Grid>
			</Container>

			<CreateTask title={t('NewTask')} open={openTask} onClose={() => setOpenTask(false)} />
		</>
	);
};

ServiceCard.propTypes = {
	service: PropTypes.object.isRequired,
	onStateEdit: PropTypes.func.isRequired,
};
