// import api from 'api/axios';
import React, { useState, useMemo } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDetails, showToast, defaultDateFormat } from 'utils/utils';
import { Modal, Button, InputUpload, BasicTable, MenuDropdown } from 'components';
import { Box, Grid, TableCell, TableRow, MenuItem, Link } from '@mui/material';
import { DOCUMENT_STATE, DEFAULT_PAGE_SIZE, DOCUMENT_STATE_OPTIONS } from 'constants/general';
import qs from 'query-string';
import { debounce, uniqueId } from 'lodash';

const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.h2,
	fontWeight: theme.typography.fontWeight.bold,
}));
const StyledLink = styled(Link)(({ theme }) => ({
	whiteSpace: 'nowrap',
	color: theme.palette.base.black,
	textDecoration: 'none',
}));

const Indicator = styled('p')(({ theme, state }) => {
	const color = {
		[DOCUMENT_STATE.NEW]: theme.palette.default.link,
		[DOCUMENT_STATE.REVIEWED]: theme.palette.default.warning,
		[DOCUMENT_STATE.PROCESSED]: theme.palette.default.success,
	};

	return {
		margin: 0,
		textTransform: 'lovercase',
		color: color[state],
	};
});

export const Documents = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [fileName, setFileName] = useState('');
	const [documentType, setDocumentType] = useState('');
	const [createdByName, setCreatedBy] = useState('');
	const [state, setState] = useState('');
	// const [fetch, setFetch] = useState('');
	// const [itemDelete, setItemDelete] = useState('');

	const query = qs.stringify({ page, pageSize: DEFAULT_PAGE_SIZE, fileName, documentType, createdByName, state });
	const { details: documents } = useDetails(`/admin/documents?${query}`, [], [page, fileName, documentType, createdByName, state]);

	const handlePageChange = (e, page) => {
		setPage(page);
	};
	const handleClick = item => {
		navigate('/documents/' + item.id);
	};
	// const handleDelete = async id => {
	// 	try {
	// 		await api.delete(`/documents/${id}`);
	// 		setFetch(uniqueId());
	// 		setItemDelete('');

	// 		showToast({
	// 			title: t('DeleteFileSuccess'),
	// 			type: 'success',
	// 		});
	// 	} catch (error) {
	// 		showToast({
	// 			title: t('SomethingWrongTryAgain'),
	// 			type: 'error',
	// 		});
	// 	}
	// };
	// const handleUpload = async files => {
	// 	try {
	// 		await api.post('/documents', { document: files }, { headers: { 'Content-Type': 'multipart/form-data' } });
	// 		setFetch(uniqueId());

	// 		showToast({
	// 			title: t('UploadFileSuccess'),
	// 			type: 'success',
	// 		});
	// 	} catch (error) {
	// 		showToast({
	// 			title: t('SomethingWrongTryAgain'),
	// 			type: 'error',
	// 		});
	// 	}
	// };

	const documentSearch = useMemo(() => debounce(e => setFileName(e.target.value), 500), []);
	const documentTypeSelect = e => setDocumentType(e.target.value);
	const uploadedBySearch = useMemo(() => debounce(e => setCreatedBy(e.target.value), 500), []);
	const statusSelect = e => setState(e.target.value);

	const filters = [
		{ type: 'search', name: 'fileName', onChange: documentSearch },
		{
			type: 'select',
			options: [{ label: 'Type', value: 'type' }],
			field: { value: documentType, onChange: documentTypeSelect },
		},
		{ type: 'search', name: 'createdBy', onChange: uploadedBySearch },
		{ type: 'none' },
		{
			type: 'select',
			options: [...DOCUMENT_STATE_OPTIONS],
			field: { value: state, onChange: statusSelect },
		},
		{ type: 'none' },
	];

	return (
		<>
			<Title sx={{ mt: 3, ml: 1 }}>{t('Documents')}</Title>
			{/* <Grid item xs={12}>
				<InputUpload
					onChange={handleUpload}
					multiple
					hideList
					text={t('UploadDocumentDocuments')}
					sx={{ mt: 2, '.drop-area': { height: '80px', border: '2px dashed #DDDDDD', background: 'white' } }}
				/>
			</Grid> */}

			<BasicTable
				sx={{ mt: 5 }}
				head={[t('DocumentTitle'), t('DocumentType'), t('DocumentUploadedBy'), t('Uploaded'), t('Status'), t('Actions')]}
				filters={filters}
				page={page}
				pageCount={documents.pageCount}
				onPageChange={handlePageChange}>
				{documents?.data?.map(item => (
					<TableRow key={item.id}>
						<TableCell onClick={() => handleClick(item)}>{item.fileName}</TableCell>
						<TableCell onClick={() => handleClick(item)}>'Tipas'</TableCell>
						<TableCell onClick={() => handleClick(item)}>{item.createdBy}</TableCell>
						<TableCell onClick={() => handleClick(item)}>{defaultDateFormat(item.createdAt)}</TableCell>
						<TableCell onClick={() => handleClick(item)}>
							<Indicator state={item.state}>{t(item.state)}</Indicator>
						</TableCell>
						<TableCell align="right">
							<MenuDropdown>
								{/* <MenuItem onClick={() => setItemDelete(item.id)}>{t('Delete')}</MenuItem> */}
								<MenuItem onClick={() => handleClick(item)}>{t('Preview')}</MenuItem>
								<MenuItem>
									<StyledLink href={item.url} target="_blank" rel="noopener">
										{t('Open')}
									</StyledLink>
								</MenuItem>
							</MenuDropdown>
						</TableCell>
					</TableRow>
				))}
			</BasicTable>

			{/* <Modal title={t('AreYouSureToDelete') + '?'} open={!!itemDelete} onClose={() => setItemDelete('')} width="40%">
				<Box sx={{ mt: 3, textAlign: 'right' }}>
					<Button variant="text" sx={{ color: 'gray' }} onClick={() => setItemDelete('')}>
						{t('Cancel')}
					</Button>
					<Button variant="outlined" onClick={() => handleDelete(itemDelete)}>
						{t('Delete')}
					</Button>
				</Box>
			</Modal> */}
		</>
	);
};
