export const REGEX = {
	NUMBER: '[0-9]',
	LETTER: '[A-Z]',
};

export const ALERT = {
	SUCCESS: 'success',
	ERROR: 'error',
	INFO: 'info',
	WARNING: 'warning',
};

export const TASK_PRIORITY = {
	LOW: 'low',
	MEDIUM: 'medium',
	HIGH: 'high',
};

export const STATE = {
	NEW: 'new',
	ONGOING: 'ongoing',
	FINISHED: 'finished',
};

export const STATE_OPTIONS = [
	{ label: 'New', value: 'new' },
	// { label: 'Ongoing', value: 'ongoing' },
	{ label: 'Finished', value: 'finished' },
];

export const DOCUMENT_STATE = {
	NEW: 'new',
	REVIEWED: 'reviewed',
	PROCESSED: 'processed',
};

export const DOCUMENT_STATE_OPTIONS = [
	{ label: 'New', value: 'new' },
	{ label: 'Reviewed', value: 'reviewed' },
	{ label: 'Processed', value: 'processed' },
];

export const CLIENT_STATUS = {
	ACTIVE: 'active',
	INACTIVE: 'inactive',
};

export const PAYMENT_STATUS = {
	PAID: 'paid',
	UNPAID: 'unpaid',
};

export const EMPLOYEE_POSITION = {
	accountant: { label: 'Accountant', value: 'accountant' },
};

export const DEFAULT_PAGE_SIZE = 10;
