import api from 'api/axios';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { removeSpaces, showToast } from 'utils/utils';
import { LoginLayout } from './components/LoginLayout';
import { Button } from 'components';
import { authSchema } from './authSchema';
import { EmailField } from './components/EmailField';

const Success = styled('p')(({ theme }) => ({
	color: theme.palette.default.success,
	fontWeight: theme.typography.fontWeight.bold,
}));

const initialValues = {
	email: '',
	password: '',
	repeatPassword: '',
};

export const PasswordForgot = () => {
	const { t } = useTranslation();

	const [success, setSuccess] = useState(false);

	const forgot = async values => {
		try {
			await api.post('/password-forgot', { email: values.email });
			setSuccess(true);
		} catch (error) {
			const message = error.response?.data?.message;

			if (message === 'UserNotFound') {
				showToast({
					title: t('UserNotFound'),
					type: 'error',
				});
			} else {
				showToast({
					title: t('ForgotError'),
					type: 'error',
				});
			}
		}
	};

	const handleSubmit = async values => {
		forgot(values);
	};

	const schema = {
		email: authSchema.email,
	};

	return (
		<LoginLayout type={'forgot'}>
			<Formik initialValues={initialValues} validationSchema={Yup.object(schema)} onSubmit={handleSubmit}>
				{({ values, setFieldValue, touched, errors, setErrors }) => (
					<Form>
						<EmailField
							id="email"
							name="email"
							label={t('Email')}
							errors={errors}
							touched={touched}
							value={values.email}
							onChange={e => {
								setFieldValue('email', removeSpaces(e.target.value));
								setSuccess(false);
							}}
						/>
						{success && <Success>{t('SendConfirmationToYourEmail')}</Success>}

						<Button type="submit" fullwidth="true" sx={{ mt: 5, mb: 4 }} disabled={!values.email || !!errors.email}>
							{t('Submit')}
						</Button>
					</Form>
				)}
			</Formik>
		</LoginLayout>
	);
};
