import React from 'react';
import { SvgIcon } from '@mui/material';

function UsersIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 21 21" fill="none">
			<g clipPath="url(#clip0_902_214)">
				<path
					d="M14.875 18.375V16.625C14.875 15.6967 14.5063 14.8065 13.8499 14.1501C13.1935 13.4937 12.3033 13.125 11.375 13.125H4.375C3.44674 13.125 2.5565 13.4937 1.90013 14.1501C1.24375 14.8065 0.875 15.6967 0.875 16.625V18.375"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
				/>
				<path
					d="M7.875 9.625C9.808 9.625 11.375 8.058 11.375 6.125C11.375 4.192 9.808 2.625 7.875 2.625C5.942 2.625 4.375 4.192 4.375 6.125C4.375 8.058 5.942 9.625 7.875 9.625Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
				/>
				<path
					d="M20.125 18.375V16.625C20.1244 15.8495 19.8663 15.0962 19.3912 14.4833C18.9161 13.8704 18.2509 13.4326 17.5 13.2388"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
				/>
				<path
					d="M14 2.73877C14.7529 2.93153 15.4202 3.36938 15.8967 3.98329C16.3732 4.5972 16.6319 5.35225 16.6319 6.12939C16.6319 6.90654 16.3732 7.66159 15.8967 8.2755C15.4202 8.88941 14.7529 9.32726 14 9.52002"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
				/>
			</g>
		</SvgIcon>
	);
}

export default UsersIcon;
