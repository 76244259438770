import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { AddEmployee } from 'components';
import { UserIcon, CompanyIcon, PhoneIcon, EmailIcon, EditIcon } from 'assets/icons';

const Container = styled('div')(({ theme }) => ({
	borderRadius: 8,
	padding: '24px 16px',
	backgroundColor: theme.palette.base.white001,
}));
const IconWrapper = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.gray002,
	width: 48,
	height: 48,
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.base,
	fontWeight: theme.typography.fontWeight.bold,
}));
const SubTitle = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
	textTransform: 'capitalize',
}));
const Text = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.xs,
	fontWeight: theme.typography.fontWeight.bold,
}));

export const EmployeeCard = ({ employee = {}, assignedClients = [], onUpdate }) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const [openEmployee, setOpenEmployee] = useState(false);

	return (
		<>
			<Container>
				<Stack direction="row" justifyContent="flex-end">
					<EditIcon onClick={() => setOpenEmployee(true)} sx={{ color: theme.palette.gray003, cursor: 'pointer' }} />
				</Stack>
				<Stack direction="row" alignItems="center" sx={{ pb: 2, borderBottom: '1px solid #F8F8F8' }}>
					<IconWrapper>
						<UserIcon sx={{ fontSize: '25px' }} />
					</IconWrapper>
					<Stack sx={{ ml: 2, flex: 1 }}>
						<Title>{employee.name}</Title>
						<SubTitle>{t(employee.position)}</SubTitle>
					</Stack>
				</Stack>
				<Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
					<PhoneIcon sx={{ color: theme.palette.gray003, fontSize: '18px', mr: 1 }} />
					{employee.phone}
				</Stack>
				<Stack direction="row" alignItems="center">
					<EmailIcon sx={{ color: theme.palette.gray003, fontSize: '18px', mr: 1 }} />
					{employee.email}
				</Stack>
			</Container>
			<Container sx={{ mt: 1 }}>
				<Stack direction="row" alignItems="center" sx={{ py: 1, borderBottom: '1px solid #DDDDDD' }}>
					<Text>
						{t('AssignedClients')} ({assignedClients?.length})
					</Text>
				</Stack>
				{assignedClients?.map(item => (
					<Stack key={item.id} direction="row" alignItems="center" sx={{ py: 2, borderBottom: '1px solid #F8F8F8' }}>
						<IconWrapper sx={{ width: 24, height: 24 }}>
							<CompanyIcon sx={{ fontSize: '12px' }} />
						</IconWrapper>
						<Stack sx={{ ml: 1, flex: 1 }}>
							<Text>{item.companyName}</Text>
						</Stack>
					</Stack>
				))}
			</Container>

			<AddEmployee
				employee={employee}
				title={t('EditEmployee')}
				open={openEmployee}
				onClose={() => setOpenEmployee(false)}
				onSuccess={onUpdate}
			/>
		</>
	);
};

EmployeeCard.propTypes = {
	employee: PropTypes.object.isRequired,
	assignedClients: PropTypes.array.isRequired,
	onUpdate: PropTypes.func,
};
