import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

export const Spinner = props => {
	const size = props.size || 100;

	return (
		<Box sx={{ position: 'relative' }}>
			<CircularProgress
				variant="determinate"
				sx={{
					color: theme => theme.palette.gray002,
				}}
				size={size}
				thickness={4}
				{...props}
				value={100}
			/>
			<CircularProgress
				variant="indeterminate"
				disableShrink
				sx={{
					color: theme => theme.palette.gold002,
					animationDuration: '550ms',
					position: 'absolute',
					left: 0,
					[`& .${circularProgressClasses.circle}`]: {
						strokeLinecap: 'round',
					},
				}}
				size={100}
				thickness={4}
				{...props}
			/>
		</Box>
	);
};

Spinner.propTypes = {
	size: PropTypes.number,
};
