import api from 'api/axios';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { schema } from './schema';
import { Formik, Form, Field } from 'formik';
import { Input, InputUpload, Modal, Button, Select, Checkbox } from 'components';
import { Box, Stack, Grid, Divider, useTheme, useMediaQuery } from '@mui/material';
import { removeSpaces, showToast } from 'utils/utils';
import { PhoneIcon, EmailIcon } from 'assets/icons';
import { CLIENT_STATUS } from 'constants/general';

const GroupLabel = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.gray003,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
	borderBottom: `1px solid ${theme.palette.gray002}`,
}));
const CategoryLabel = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.black,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const CheckboxLabel = styled('p')(({ theme }) => ({
	margin: 0,
	whiteSpace: 'nowrap',
	color: theme.palette.base.black002,
	fontWeight: theme.typography.fontWeight.bold,
}));

const initial = {
	logo: '',
	state: '',
	companyName: '',
	companyType: '',
	PVMCode: '',
	companyCode: '',
	contactPersonName: '',
	contactPersonPosition: '',
	phone: '+370',
	email: '',

	correspondanceCity: '',
	correspondanceStreet: '',
	correspondanceCountry: '',
	correspondanceZIPCode: '',
	registrationCity: '',
	registrationStreet: '',
	registrationCountry: '',
	registrationZIPCode: '',

	companyManager: '',
	workingHours: '',
	additionalInfo: '',

	serviceAgreementNumber: '',
	serviceAgreementDate: '',
	payments: {
		pvm: false,
		sodra: false,
		gpm: false,
		psd: false,
	},
	reports: {
		yearly: false,
		monthly: false,
	},
	// indicators: false,
};

export const AddClient = ({ client, title, open, onClose, onSuccess }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const { t } = useTranslation();
	const theme = useTheme();
	const [logo, setLogo] = useState('');
	const initialValues = { ...initial, ...client };

	const addNewClient = async values => {
		try {
			const res = await api.post('/clients/create', { ...values, logo }, { headers: { 'Content-Type': 'multipart/form-data' } });
			// await api.post(`/clients/logo/${res.data?.id}`, { logo }, { headers: { 'Content-Type': 'multipart/form-data' } });
			setLogo('');
			onSuccess();
			onClose();
			showToast({
				title: t('CreateClientSuccess'),
				type: 'success',
			});
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};
	const updateClient = async (id, values) => {
		const updateBody = { ...values };

		try {
			await api.put(`/clients/${id}`, updateBody);
			await api.post(`/clients/logo/${id}`, { logo }, { headers: { 'Content-Type': 'multipart/form-data' } });

			setLogo('');
			onSuccess();
			onClose();

			showToast({
				title: t('UpdateClientSuccess'),
				type: 'success',
			});
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const handleSubmit = async values => {
		if (client?.id) {
			updateClient(client?.id, values);
		} else {
			addNewClient(values);
		}
	};

	const handleUpload = file => {
		setLogo(file);
	};

	const spacing2 = isMobile ? 0 : 2;

	return (
		<Modal title={title} open={open} onClose={onClose} width="80%">
			<Formik initialValues={initialValues} validationSchema={Yup.object(schema)} onSubmit={handleSubmit}>
				{({ values, setFieldValue, touched, errors, setErrors }) => (
					<Form id="add-new-client">
						<Grid container>
							<Grid item xs={12} md={4} sx={{ pr: spacing2 }}>
								<InputUpload onChange={handleUpload} label={t('Logo')} text={t('UploadLogo')} allowRemove sx={{ mt: 2 }} />
								<Field
									id="state"
									name="state"
									component={Select}
									label={t('State')}
									placeholder={t('State')}
									value={values.state}
									fullWidth
									options={[
										{ label: 'Active', value: CLIENT_STATUS.ACTIVE },
										{ label: 'Inactive', value: CLIENT_STATUS.INACTIVE },
									]}
									sx={{ mt: 2 }}
								/>
							</Grid>
							<Grid item xs={12} md={4} sx={{ pl: spacing2, pr: spacing2 }}>
								<Field
									id="companyName"
									name="companyName"
									component={Input}
									label={t('CompanyTitle')}
									placeholder={t('Title')}
									errors={errors}
									touched={touched}
									value={values.companyName}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
										required: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Field
									id="companyType"
									name="companyType"
									component={Input}
									label={t('CompanyType')}
									placeholder={t('Type')}
									errors={errors}
									touched={touched}
									value={values.companyType}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Field
									id="PVMCode"
									name="PVMCode"
									component={Input}
									label={t('PVMCode')}
									placeholder={t('PVMCode')}
									errors={errors}
									touched={touched}
									value={values.PVMCode}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Field
									id="companyCode"
									name="companyCode"
									component={Input}
									label={t('Code')}
									placeholder={t('Code')}
									errors={errors}
									touched={touched}
									value={values.companyCode}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
										required: true,
									}}
									sx={{ mt: 2 }}
								/>
							</Grid>
							<Grid item xs={12} md={4} sx={{ pl: spacing2 }}>
								<Field
									id="contactPersonName"
									name="contactPersonName"
									component={Input}
									label={t('ContactPerson')}
									placeholder={t('NameSurname')}
									errors={errors}
									touched={touched}
									value={values.contactPersonName}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Field
									id="contactPersonPosition"
									name="contactPersonPosition"
									component={Input}
									label={t('Position')}
									placeholder={t('Position')}
									errors={errors}
									touched={touched}
									value={values.contactPersonPosition}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Stack direction="row">
									<PhoneIcon sx={{ color: theme.palette.gray003, fontSize: '18px', mt: '53px', mr: 1 }} />
									<Field
										id="phone"
										name="phone"
										component={Input}
										label={t('PhoneNumber')}
										placeholder={t('+370')}
										errors={errors}
										touched={touched}
										value={values.phone}
										fullWidth
										type="text"
										variant="standard"
										InputLabelProps={{
											shrink: true,
											required: true,
										}}
										sx={{ mt: 2 }}
									/>
								</Stack>
								<Stack direction="row">
									<EmailIcon sx={{ color: theme.palette.gray003, fontSize: '18px', mt: '53px', mr: 1 }} />
									<Field
										id="email"
										name="email"
										component={Input}
										label={t('Email')}
										placeholder={t('Email')}
										errors={errors}
										touched={touched}
										value={values.email}
										onChange={e => setFieldValue('email', removeSpaces(e.target.value))}
										fullWidth
										type="text"
										variant="standard"
										InputLabelProps={{
											shrink: true,
											required: true,
										}}
										sx={{ mt: 2 }}
									/>
								</Stack>
							</Grid>
						</Grid>

						<Divider sx={{ my: 4 }} />

						<Grid container>
							<Grid item xs={12} md={4} sx={{ pr: spacing2 }}>
								<GroupLabel>{t('CorespondenceAddress')}</GroupLabel>
								<Field
									id="correspondanceCity"
									name="correspondanceCity"
									component={Input}
									label={t('City')}
									placeholder={t('City')}
									errors={errors}
									touched={touched}
									value={values.correspondanceCity}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Field
									id="correspondanceStreet"
									name="correspondanceStreet"
									component={Input}
									label={t('Street')}
									placeholder={t('Street')}
									errors={errors}
									touched={touched}
									value={values.correspondanceStreet}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Field
									id="correspondanceCountry"
									name="correspondanceCountry"
									component={Input}
									label={t('Country')}
									placeholder={t('Country')}
									errors={errors}
									touched={touched}
									value={values.correspondanceCountry}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Field
									id="correspondanceZIPCode"
									name="correspondanceZIPCode"
									component={Input}
									label={t('PostCode')}
									placeholder={t('PostCode')}
									errors={errors}
									touched={touched}
									value={values.correspondanceZIPCode}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
							</Grid>
							<Grid item xs={12} md={4} sx={{ pl: spacing2 }}>
								<GroupLabel>{t('RegistrationAddress')}</GroupLabel>
								<Field
									id="registrationCity"
									name="registrationCity"
									component={Input}
									label={t('City')}
									placeholder={t('City')}
									errors={errors}
									touched={touched}
									value={values.registrationCity}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Field
									id="registrationStreet"
									name="registrationStreet"
									component={Input}
									label={t('Street')}
									placeholder={t('Street')}
									errors={errors}
									touched={touched}
									value={values.registrationStreet}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Field
									id="registrationCountry"
									name="registrationCountry"
									component={Input}
									label={t('Country')}
									placeholder={t('Country')}
									errors={errors}
									touched={touched}
									value={values.registrationCountry}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Field
									id="registrationZIPCode"
									name="registrationZIPCode"
									component={Input}
									label={t('PostCode')}
									placeholder={t('PostCode')}
									errors={errors}
									touched={touched}
									value={values.registrationZIPCode}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
							</Grid>
						</Grid>

						<Divider sx={{ my: 4 }} />

						<Grid container>
							<Grid item xs={12} md={4} sx={{ pr: spacing2 }}>
								<Field
									id="companyManager"
									name="companyManager"
									component={Input}
									label={t('CompanyManager')}
									placeholder={t('NameSurname')}
									errors={errors}
									touched={touched}
									value={values.companyManager}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<Field
									id="workingHours"
									name="workingHours"
									component={Input}
									label={t('WorkHours')}
									placeholder={t('WorkHours')}
									errors={errors}
									touched={touched}
									value={values.workingHours}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
							</Grid>
							<Grid item xs={12} md={4} sx={{ pl: spacing2 }}>
								<Field
									id="additionalInfo"
									name="additionalInfo"
									component={Input}
									label={t('AdditionalInformation')}
									placeholder={t('AdditionalInformation')}
									errors={errors}
									touched={touched}
									value={values.additionalInfo}
									fullWidth
									type="text"
									multiline
									rows={5}
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>

						<Divider sx={{ my: 4 }} />

						<Grid container>
							<Grid item xs={12} md={8}>
								<GroupLabel>{t('ServiceAgreement')}</GroupLabel>
							</Grid>
							<Grid item xs={12} md={4}></Grid>
							<Grid item xs={12} md={4} sx={{ pr: spacing2 }}>
								<Field
									id="serviceAgreementNumber"
									name="serviceAgreementNumber"
									component={Input}
									label={t('ServiceAgreementNumber')}
									placeholder={t('ServiceAgreementNumber')}
									errors={errors}
									touched={touched}
									value={values.serviceAgreementNumber}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
							</Grid>
							<Grid item xs={12} md={4} sx={{ pl: spacing2 }}>
								<Field
									id="serviceAgreementDate"
									name="serviceAgreementDate"
									component={Input}
									label={t('ServiceAgreementDate')}
									placeholder={t('ServiceAgreementDate')}
									errors={errors}
									touched={touched}
									value={values.serviceAgreementDate}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
							</Grid>
						</Grid>

						<Divider sx={{ my: 4 }} />

						<Grid container>
							<Grid item xs={12} md={8}>
								<GroupLabel>{t('Settings')}</GroupLabel>
							</Grid>
							<Grid item xs={12} md={4}></Grid>
							<Grid container item xs={12} md={3} direction="column" sx={{ mt: 2 }}>
								<CategoryLabel>{t('Payments')}</CategoryLabel>
								<Field
									id="payments.pvm"
									name="payments.pvm"
									component={Checkbox}
									value={values.payments.pvm}
									label={<CheckboxLabel>{t('PVM')}</CheckboxLabel>}
								/>
								<Field
									id="payments.sodra"
									name="payments.sodra"
									component={Checkbox}
									value={values.payments.sodra}
									label={<CheckboxLabel>{t('Sodra')}</CheckboxLabel>}
								/>
								<Field
									id="payments.gpm"
									name="payments.gpm"
									component={Checkbox}
									value={values.payments.gpm}
									label={<CheckboxLabel>{t('GPM')}</CheckboxLabel>}
								/>
								<Field
									id="payments.psd"
									name="payments.psd"
									component={Checkbox}
									value={values.payments.psd}
									label={<CheckboxLabel>{t('PSD')}</CheckboxLabel>}
								/>
							</Grid>
							<Grid item xs={12} md={3} sx={{ mt: 2 }}>
								<CategoryLabel>{t('Reports')}</CategoryLabel>
								<Field
									id="reports.yearly"
									name="reports.yearly"
									component={Checkbox}
									value={values.reports.yearly}
									label={<CheckboxLabel>{t('YearlyReport')}</CheckboxLabel>}
								/>
								<Field
									id="reports.monthly"
									name="reports.monthly"
									component={Checkbox}
									value={values.reports.monthly}
									label={<CheckboxLabel>{t('MonthlyReport')}</CheckboxLabel>}
								/>
							</Grid>
							{/* <Grid item xs={12} md={3} sx={{ mt: 2 }}>
								<CategoryLabel>{t('FinancialIndicators')}</CategoryLabel>
								<Field
									id="indicator"
									name="indicator"
									component={Checkbox}
									value={values.indicator}
									label={<CheckboxLabel>{t('Indicator')}</CheckboxLabel>}
								/>
							</Grid> */}
						</Grid>

						<Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
							<Button type="submit" sx={{ mt: 5 }}>
								{t('Save')}
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};
