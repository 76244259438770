import useUser from 'hooks/useUser';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Box } from '@mui/material';
import { LogoIcon, BurgerIcon, ChevronIcon, UserIcon } from 'assets/icons';
import { adminMenu, clientMenu, accountantMenu } from './data';
import { useDetails } from 'utils/utils';
import { uniqueId } from 'lodash';

const Container = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.base.white001,
	'& img': {
		display: 'flex',
		margin: 'auto',
	},
}));
const MenuButton = styled('button')(({ theme }) => ({
	border: 0,
	background: 'unset',
	cursor: 'pointer',
	width: '100%',
	height: '90px',
	color: theme.palette.gray003,
}));
const Button = styled(Link)(({ theme, type }) => ({
	fontSize: theme.typography.fontSize.small,
	fontWeight: type === 'active' ? theme.typography.fontWeight.bold : theme.typography.fontWeight.bold,
	color: type === 'active' ? theme.palette.gold002 : theme.palette.gray003,
	backgroundColor: type === 'active' ? theme.palette.gold001 : '',
	borderRight: type === 'active' ? `4px solid ${theme.palette.gold002}` : '',
	display: 'flex',
	justifyContent: 'left',
	alignItems: 'center',
	padding: '0 26px 0 26px',
	margin: '16px 0',
	borderRadius: theme.shape.br1,
	textDecoration: 'none',
	height: 40,
}));
const Label = styled('p')(() => ({
	margin: 0,
	minWidth: '130px',
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
	margin: '40px 20px 34px 20px',
	borderColor: '#DDDDDD',
}));
const Logo = styled(Link)(() => ({
	display: 'flex',
	textDecoration: 'none',
}));
const TitleDark = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.base.black,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const TextGray = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.gray003,
	fontSize: theme.typography.fontSize.xs,
	fontWeight: theme.typography.fontWeight.bold,
}));
const TextDark = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
}));
const LogoContainer = styled(Box)(({ theme }) => ({
	height: 90,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
const Card = styled(Box)(({ theme }) => ({
	borderRadius: 4,
	textAlign: 'center',
	padding: '16px 16px 24px 16px',
	margin: '0px 20px',
	backgroundColor: theme.palette.base.background,
}));
const IconWrapper = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.gray002,
	width: 48,
	height: 48,
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const AdminCard = () => {
	const { t } = useTranslation();
	const { user } = useUser();
	const userId = user.clientIds?.[0];

	const [fetch, setFetch] = useState('loading');

	const { details: employees } = useDetails(`/clients/employees/${userId}`, [], [fetch]);

	useEffect(() => {
		if (userId) {
			setFetch(uniqueId());
		}
	}, [userId]);

	const adminInfo = employees?.[0] ? employees[0] : false;

	return (
		<>
			{adminInfo ? (
				<Card>
					<TitleDark>{t('Administrate')}</TitleDark>
					<Divider sx={{ mt: 2, mb: 3 }} />
					<Box display="flex" flexDirection="column" alignItems="center">
						<IconWrapper sx={{ width: 40, height: 40 }}>
							<UserIcon sx={{ fontSize: '21px' }} />
						</IconWrapper>
						<TitleDark sx={{ mt: 1 }}>{'Vardenė Pavardenė'}</TitleDark>
						<TextGray>{'position'}</TextGray>
						<TextDark sx={{ mt: 3 }}>{'+370 68 888 888'}</TextDark>
						<TextDark>{'vardene@dinamine.lt'}</TextDark>
					</Box>
				</Card>
			) : (
				''
			)}
		</>
	);
};

const NavButton = ({ button, showLabel, count }) => {
	const location = useLocation();
	const { t } = useTranslation();

	return (
		<Button to={button.url} type={button.url === `/${location.pathname.split('/')[1]}` ? 'active' : ''}>
			{button.icon}
			{showLabel && (
				<>
					<Label sx={{ ml: 1, flex: 1 }}>{t(button.label)}</Label>
					<ChevronIcon sx={{ fontSize: 10, transform: 'rotate(-90deg)' }} />
				</>
			)}
		</Button>
	);
};

export const Menu = ({ onClick, small, type }) => {
	const getMenuData = type => {
		return { admin: adminMenu, client: clientMenu, accountant: accountantMenu }[type];
	};

	return (
		<Container sx={{ minWidth: small ? '70px' : '243px' }}>
			{small ? (
				<MenuButton onClick={onClick} sx={{ p: 2 }}>
					<BurgerIcon sx={{ fontSize: 20 }} />
				</MenuButton>
			) : (
				<LogoContainer>
					<Logo to="/">
						<LogoIcon width="37" />
						<Box sx={{ ml: 1, mt: '4px' }}>
							<TitleDark>DINAMINĖ APSKAITA</TitleDark>
							<TextGray>Savitarna</TextGray>
						</Box>
					</Logo>
				</LogoContainer>
			)}
			{getMenuData(type).map((item, idx) => (
				<NavButton button={item} showLabel={!small} key={idx} />
			))}
			{type === 'client' && (
				<>
					<StyledDivider />
					<AdminCard />
				</>
			)}
		</Container>
	);
};

Menu.propTypes = {
	onClick: PropTypes.func,
	small: PropTypes.bool.isRequired,
};

NavButton.propTypes = {
	button: PropTypes.object,
	showLabel: PropTypes.bool,
	count: PropTypes.number,
};
