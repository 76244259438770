import React from 'react';
import { SvgIcon } from '@mui/material';

function DashboardIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 21 21" fill="none">
			<path
				d="M16.625 2.625H4.375C3.4085 2.625 2.625 3.4085 2.625 4.375V16.625C2.625 17.5915 3.4085 18.375 4.375 18.375H16.625C17.5915 18.375 18.375 17.5915 18.375 16.625V4.375C18.375 3.4085 17.5915 2.625 16.625 2.625Z"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.625 7.875H18.375"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.875 18.375V7.875"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default DashboardIcon;
