import api from 'api/axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { schema } from './schema';
import { showToast, formatToMinutes, formatToHours } from 'utils/utils';
import { Formik, Form, Field } from 'formik';
import { PrioritySelect } from './components/PrioritySelect';
import { Modal, Button, Select, SearchDropdownSelect, Checkbox, InputUpload, Input, InputDatePicker } from 'components';
import { Box, Stack, Grid, Divider, useMediaQuery } from '@mui/material';
import { STATE, TASK_PRIORITY } from 'constants/general';

const HeadingText = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
	borderBottom: '1px solid #DDDDDD',
}));
const CheckboxLabel = styled('p')(({ theme }) => ({
	margin: 0,
	whiteSpace: 'nowrap',
	color: theme.palette.base.black002,
	fontWeight: theme.typography.fontWeight.bold,
}));
const GroupLabel = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.gray003,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const InputLabel = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.black,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));

const init = {
	name: '',
	type: '',
	// repeat: '',
	description: '',
};

export const CreateTask = ({ url = 'admin', task = { state: STATE.NEW }, title, open, onClose, onSuccess }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const { t } = useTranslation();
	const today = moment().format('YYYY-MM-DD');
	const initialValues = { ...init, ...task, state: task.state === STATE.NEW ? false : true };

	const [employees, setEmployees] = useState([]);
	const [company, setCompany] = useState([]);
	const [files, setFiles] = useState([]);
	const [startDate, setStartDate] = useState({ date: today, time: 8 * 60 });
	const [endDate, setEndDate] = useState({ date: today, time: 12 * 60 });
	const [priority, setPriority] = useState(TASK_PRIORITY.MEDIUM);

	const resetState = () => {
		setEmployees([]);
		setCompany([]);
		setFiles([]);
		setStartDate({ date: today, time: 8 * 60 });
		setEndDate({ date: today, time: 12 * 60 });
		setPriority(TASK_PRIORITY.MEDIUM);
	};

	useEffect(() => {
		if (open === false) {
			resetState();
		}
	}, [open]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (task?.id) {
			setStartDate({ date: moment(task.startDate).format('YYYY-MM-DD'), time: formatToMinutes(task.startDate) });
			setEndDate({ date: moment(task.endDate).format('YYYY-MM-DD'), time: formatToMinutes(task.endDate) });
			setPriority(task.priority);
			setEmployees(task?.employees);
			setCompany([task?.client]);
		}
	}, [task?.id, task?.startDate, task?.endDate, task?.priority, task?.client, task?.employees]);

	const createTask = async values => {
		const from = `${startDate.date}T${formatToHours(startDate.time)}`;
		const to = `${endDate.date}T${formatToHours(endDate.time)}`;
		const employeeIds = employees.map(item => item.id);
		const companyId = company[0]?.id || '';

		try {
			const { data } = await api.post(`/${url}/tasks`, {
				name: values.name,
				startDate: from,
				endDate: to,
				type: values.type,
				priority,
				description: values.description,
				state: values.state === false ? STATE.NEW : STATE.FINISHED,
				employeeIds,
				companyId,
			});
			files?.length > 0 &&
				(await api.post(
					`/${url}/documents/task/${data.id}`,
					{ document: files },
					{ headers: { 'Content-Type': 'multipart/form-data' } },
				));
			resetState();
			onSuccess();
			onClose();
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const updateTask = async (id, values) => {
		const from = `${startDate.date}T${formatToHours(startDate.time)}`;
		const to = `${endDate.date}T${formatToHours(endDate.time)}`;
		const employeeIds = employees.map(item => item.id);
		const companyId = company[0]?.id || '';

		const updateBody = {
			name: values.name,
			startDate: from,
			endDate: to,
			type: values.type,
			priority,
			description: values.description,
			state: values.state === false ? STATE.NEW : STATE.FINISHED,
			employeeIds,
			companyId,
		};

		try {
			await api.put(`/${url}/tasks/${id}`, updateBody);
			resetState();
			onSuccess();
			onClose();

			showToast({
				title: t('UpdateTaskSuccess'),
				type: 'success',
			});
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const handleSubmit = async values => {
		if (task?.id) {
			updateTask(task.id, values);
		} else {
			createTask(values);
		}
	};

	const handleUpload = files => {
		setFiles(files);
	};

	const spacing2 = isMobile ? 0 : 2;
	const initEmployeesValues = task?.employees?.length > 0 ? task.employees.map(employee => employee.id) : [];

	return (
		<Modal title={title} open={open} onClose={onClose} width="80%">
			<Formik initialValues={initialValues} validationSchema={Yup.object(schema)} onSubmit={handleSubmit}>
				{({ values, setFieldValue, touched, errors, setErrors }) => (
					<Form>
						<Grid container>
							<Grid item xs={12} md={4} sx={{ pr: spacing2 }}>
								<Field
									id="name"
									name="name"
									component={Input}
									label={t('Title')}
									placeholder={t('Title')}
									errors={errors}
									touched={touched}
									value={values.name}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
										required: true,
									}}
									sx={{ mt: 4 }}
								/>
								<InputDatePicker
									initDate={startDate.date}
									startTime={startDate.time}
									onChange={date => setStartDate(date)}
									label={t('StartDate')}
									InputLabelProps={{ required: true }}
									sx={{ mt: 2 }}
								/>
								<InputDatePicker
									initDate={endDate.date}
									startTime={endDate.time}
									onChange={date => setEndDate(date)}
									label={t('EndDate')}
									InputLabelProps={{ required: true }}
									shift
									sx={{ mt: 2 }}
								/>
								<Stack direction="row">
									<Field
										id="type"
										name="type"
										component={Select}
										label={t('Type')}
										placeholder={t('Type')}
										value={values.type}
										fullWidth
										options={[{ label: 'Type', value: 'type' }]}
										sx={{ mt: 2, pr: 1, width: '100%' }}
									/>
									<Box sx={{ mt: 2, pl: 1, width: '96px' }}>
										<InputLabel>{t('Priority')}</InputLabel>
										<PrioritySelect
											value={priority}
											onChange={priority => setPriority(priority)}
											sx={{ height: '50px', display: 'flex', alignItems: 'center' }}
										/>
									</Box>
								</Stack>
								{/* <Field
									id="repeat"
									name="repeat"
									component={Select}
									label={t('Repeat')}
									placeholder={t('Never')}
									value={values.repeat}
									fullWidth
									options={[
										{ label: 'Never', value: 'never' },
										{ label: 'EveryDay', value: 'everyDay' },
										{ label: 'EveryWorkday', value: 'everyWorkday' },
										{ label: 'EveryWeek', value: 'everyWeek' },
										{ label: 'EveryMonth', value: 'everyMonth' },
										{ label: 'EveryYear', value: 'everyYear' },
									]}
									disabled
									sx={{ mt: 2 }}
								/> */}
								<Field
									id="description"
									name="description"
									component={Input}
									label={t('Description')}
									placeholder={t('Description')}
									errors={errors}
									touched={touched}
									value={values.description}
									fullWidth
									type="text"
									multiline
									rows={5}
									variant="standard"
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Stack>
									<Field
										id="state"
										name="state"
										value={values.state}
										component={Checkbox}
										label={<CheckboxLabel>{t('Done')}</CheckboxLabel>}
										sx={{ mt: 1 }}
									/>
								</Stack>
							</Grid>

							<Grid item xs={12} md={4} sx={{ pl: spacing2 }}>
								<HeadingText sx={{ mt: 4 }}>{t('Assign')}</HeadingText>
								<SearchDropdownSelect
									onChange={options => setEmployees(options)}
									values={employees}
									initialValues={initEmployeesValues}
									placeholder={t('SearchEmployee')}
									optionLabel="name"
									optionSublabel="position"
									url="/employees/list/light"
									type="user"
									multiple
								/>
							</Grid>
							<Grid item xs={12} md={4} sx={{ pl: spacing2 }}>
								<HeadingText sx={{ mt: 4 }}>{t('AssociateWith')}</HeadingText>
								<SearchDropdownSelect
									onChange={option => setCompany(option)}
									values={company}
									initialValues={task?.client?.id}
									placeholder={t('SearchCompany')}
									optionLabel="companyName"
									url="/clients/list/light"
									type="company"
								/>
							</Grid>
						</Grid>
						{!task?.id && (
							<>
								<Divider sx={{ my: 4 }} />
								<Grid item xs={12}>
									<GroupLabel>{t('Documents')}</GroupLabel>
									<InputUpload
										onChange={handleUpload}
										multiple
										allowRemove
										text={t('UploadDocumentDocuments')}
										sx={{ mt: 2, '.drop-area': { height: '80px', border: '2px dashed #DDDDDD' } }}
									/>
								</Grid>
							</>
						)}
						<Grid item xs={12} md={4}></Grid>
						<Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
							<Button
								type="submit"
								sx={{ mt: 5 }}
								disabled={
									!values.name ||
									!endDate.date ||
									!startDate.date ||
									endDate.date === 'Invalid Date' ||
									startDate.date === 'Invalid Date' ||
									!priority
								}>
								{t('Save')}
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};
