import React from 'react';
import { SvgIcon } from '@mui/material';

function CloseIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 14 14">
			<path d="M13 1L1 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M1 1L13 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</SvgIcon>
	);
}

export default CloseIcon;
