import React from 'react';
import { SvgIcon } from '@mui/material';

function EyeIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 21 22" fill="none">
			<g>
				<path
					d="M0.875 11C0.875 11 4.375 4 10.5 4C16.625 4 20.125 11 20.125 11C20.125 11 16.625 18 10.5 18C4.375 18 0.875 11 0.875 11Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
				/>
				<path
					d="M10.5 13.625C11.9497 13.625 13.125 12.4497 13.125 11C13.125 9.55025 11.9497 8.375 10.5 8.375C9.05025 8.375 7.875 9.55025 7.875 11C7.875 12.4497 9.05025 13.625 10.5 13.625Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
				/>
			</g>
		</SvgIcon>
	);
}

export default EyeIcon;
