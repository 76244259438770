import React from 'react';
import { SvgIcon } from '@mui/material';

function MoreVerticalIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 16 16">
			<path
				d="M7.99992 8.66634C8.36811 8.66634 8.66658 8.36786 8.66658 7.99967C8.66658 7.63148 8.36811 7.33301 7.99992 7.33301C7.63173 7.33301 7.33325 7.63148 7.33325 7.99967C7.33325 8.36786 7.63173 8.66634 7.99992 8.66634Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M7.99992 4.00032C8.36811 4.00032 8.66658 3.70185 8.66658 3.33366C8.66658 2.96547 8.36811 2.66699 7.99992 2.66699C7.63173 2.66699 7.33325 2.96547 7.33325 3.33366C7.33325 3.70185 7.63173 4.00032 7.99992 4.00032Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M7.99992 13.3333C8.36811 13.3333 8.66658 13.0349 8.66658 12.6667C8.66658 12.2985 8.36811 12 7.99992 12C7.63173 12 7.33325 12.2985 7.33325 12.6667C7.33325 13.0349 7.63173 13.3333 7.99992 13.3333Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default MoreVerticalIcon;
