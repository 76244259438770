import api from 'api/axios';
import { uniqueId } from 'lodash';
import React, { useState } from 'react';
import { useDetails, showToast } from 'utils/utils';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'components';
import { Stack, ButtonBase } from '@mui/material';
import { AddIcon, UserIcon, CloseIcon, EditIcon, TrashIcon } from 'assets/icons';
import useUser from 'hooks/useUser';

const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const Text = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.r,
}));
const TextGray = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.r,
	color: theme.palette.gray003,
}));
const Container = styled('div')(({ theme }) => ({
	borderRadius: 8,
	padding: '16px',
	backgroundColor: theme.palette.base.white001,
}));
const IconWrapper = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.gray002,
	width: 48,
	height: 48,
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
const ActionButton = styled(ButtonBase)(({ theme }) => ({
	color: theme.palette.gray003,
	fontSize: theme.typography.fontSize.small,
}));

export const Comments = ({ userRole, groupId, type }) => {
	const { t } = useTranslation();
	const { user } = useUser();

	const [fetch, setFetch] = useState('');
	const [comment, setComment] = useState({ id: '', value: '' });
	const [addOpen, setAddOpen] = useState(false);
	const [editOpen, setEditOpen] = useState('1');
	const [editComment, setEditComment] = useState({ id: '', value: '' });
	const { details: comments } = useDetails(`/${userRole}/comments/${type}/${groupId}`, [], [fetch]);

	const handleSubmit = async comment => {
		try {
			await api.post(`/${userRole}/comments/${type}/${groupId}`, { text: comment.value });
			setFetch(comment.id);
			handleClose();
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const handleEditSubmit = async (id, comment) => {
		try {
			await api.put(`/admin/comments/${id}`, { text: comment.value });
			setFetch(comment.id);
			setEditOpen('');
			setEditComment({ id: '', value: '' });
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const handleClose = () => {
		setAddOpen(false);
		setComment({ id: '', value: '' });
	};

	const handleDelete = async id => {
		try {
			await api.delete(`/admin/comments/${id}`);
			setFetch(id);
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const admin = userRole === 'admin';

	return (
		<Container>
			<Stack direction="row" alignItems="end" justifyContent="space-between" sx={{ pb: 2, borderBottom: '1px solid #DDD' }}>
				<Title sx={{}}>{t('Comments')}</Title>
				<Button variant="outlined" onClick={() => setAddOpen(true)}>
					<AddIcon sx={{ fontSize: 21, mr: 1 }} />
					{t('AddComment')}
				</Button>
			</Stack>
			{comments.length === 0 && <TextGray sx={{ pt: 2 }}>{t('NoComments')}</TextGray>}
			{comments.map((item, idx) => (
				<Stack key={idx} direction="row" sx={{ pt: 2 }}>
					<IconWrapper sx={{ width: 40, height: 40 }}>
						<UserIcon sx={{ fontSize: '21px' }} />
					</IconWrapper>
					<Stack sx={{ ml: 1, flex: 1 }}>
						<Title>{item.name}</Title>
						{editOpen === item.id ? (
							<>
								<Input
									name="editComment"
									placeholder={t('Comment')}
									field={{
										name: 'editComment',
										value: editComment.value,
										onChange: e => setEditComment({ id: uniqueId(), value: e.target.value }),
									}}
									fullWidth
									type="text"
									variant="standard"
									multiline
									rows={5}
									InputLabelProps={{
										shrink: true,
									}}
									sx={{ mt: -2 }}
								/>
								<Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
									<Button onClick={() => handleEditSubmit(item.id, editComment)}>{t('Save')}</Button>
								</Stack>
							</>
						) : (
							<Text>{item.text}</Text>
						)}
						{admin && editOpen !== item.id && (
							<Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
								<ActionButton
									onClick={() => {
										setEditComment({ id: '', value: item.text });
										setEditOpen(item.id);
									}}
									sx={{ mr: 2 }}>
									<EditIcon sx={{ fontSize: '18px', mr: 1 }} />
									{t('Edit')}
								</ActionButton>
								<ActionButton onClick={() => handleDelete(item.id)}>
									<TrashIcon sx={{ fontSize: '18px', mr: 1 }} />
									{t('Delete')}
								</ActionButton>
							</Stack>
						)}
					</Stack>
				</Stack>
			))}
			{addOpen && (
				<>
					<Stack direction="row" sx={{ pt: 2, position: 'relative' }}>
						<IconWrapper sx={{ width: 40, height: 40 }}>
							<UserIcon sx={{ fontSize: '21px' }} />
						</IconWrapper>
						<Input
							name="comment"
							label={user.name}
							placeholder={t('Comment')}
							field={{
								name: 'comment',
								value: comment.value,
								onChange: e => setComment({ id: uniqueId(), value: e.target.value }),
							}}
							fullWidth
							type="text"
							variant="standard"
							multiline
							rows={5}
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ ml: 1 }}
						/>
						<ButtonBase onClick={handleClose} sx={{ position: 'absolute', right: 0, padding: '5px' }}>
							<CloseIcon sx={{ fontSize: '10px' }} />
						</ButtonBase>
					</Stack>
					<Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
						<Button onClick={() => handleSubmit(comment)}>{t('ToComment')}</Button>
					</Stack>
				</>
			)}
		</Container>
	);
};
