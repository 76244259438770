import api from 'api/axios';
import * as Yup from 'yup';
import React from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { Formik, Form } from 'formik';
import { removeSpaces, showToast } from 'utils/utils';
import { LoginLayout } from './components/LoginLayout';
import { Box } from '@mui/material';
import { Button } from 'components';
import { authSchema } from './authSchema';
import { EmailField } from './components/EmailField';
import { PasswordField } from './components/PasswordField';

const StyledLink = styled(Link)(({ theme }) => ({
	whiteSpace: 'nowrap',
	color: theme.palette.default.link,
	fontWeight: theme.typography.fontWeight.bold,
	textDecoration: 'none',
}));

const initialValues = {
	email: '',
	password: '',
	repeatPassword: '',
};

export const PasswordReset = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const signature = searchParams.get('signature');

	const reset = async values => {
		try {
			await api.post(`/password-reset?signature=${signature}`, {
				email: values.email,
				password: values.password,
				password1: values.repeatPassword,
			});

			navigate('/login', { replace: true, state: { email: values.email } });
		} catch (error) {
			const message = error.response?.data?.message;

			if (message === 'UserNotFound') {
				showToast({
					title: t('UserNotFound'),
					type: 'error',
				});
			} else {
				showToast({
					title: t('ResetError'),
					type: 'error',
				});
			}
		}
	};

	const handleSubmit = async values => {
		reset(values);
	};

	const schema = {
		email: authSchema.email,
		password: authSchema.password,
		repeatPassword: authSchema.repeatPassword,
	};

	return (
		<LoginLayout type={'reset'}>
			<Formik initialValues={initialValues} validationSchema={Yup.object(schema)} onSubmit={handleSubmit}>
				{({ values, setFieldValue, touched, errors, setErrors }) => (
					<Form>
						<EmailField
							id="email"
							name="email"
							label={t('Email')}
							errors={errors}
							touched={touched}
							value={values.email}
							onChange={e => setFieldValue('email', removeSpaces(e.target.value))}
						/>
						<PasswordField
							id="password"
							name="password"
							label={t('Password')}
							errors={errors}
							touched={touched}
							value={values.password}
							sx={{ mt: 2 }}
						/>
						<PasswordField
							id="repeatPassword"
							name="repeatPassword"
							label={t('RepeatPassword')}
							errors={errors}
							touched={touched}
							value={values.repeatPassword}
							sx={{ mt: 2 }}
						/>
						<Box
							spacing={1}
							display="flex"
							sx={{
								pt: 1,
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}>
							<StyledLink to="/password-forgot">{t('ResendConfirmationLink')}?</StyledLink>
						</Box>
						<Button
							type="submit"
							fullwidth="true"
							sx={{ mt: 5, mb: 4 }}
							disabled={
								!values.email ||
								!values.password ||
								!values.repeatPassword ||
								!!errors.email ||
								!!errors.password ||
								!!errors.repeatPassword
							}>
							{t('Create')}
						</Button>
					</Form>
				)}
			</Formik>
		</LoginLayout>
	);
};
