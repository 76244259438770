import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { InputSearch } from 'components';

export const FilterInputSearch = ({ name, onChange }) => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<InputSearch
			name={name}
			placeholder={t('Search')}
			onChange={onChange}
			fullWidth
			sx={{
				'.MuiInputBase-root': {
					fontSize: theme.typography.fontSize.xs,
					fontWeight: theme.typography.fontWeight.bold,
					backgroundColor: theme.palette.base.white001,
					input: {
						padding: '9px 16px 8px 0px',
					},
				},
			}}
			iconSize="14px"
		/>
	);
};

FilterInputSearch.propTypes = {};
