import React from 'react';
import { SvgIcon } from '@mui/material';

function PhoneIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 18 18" fill="none">
			<path
				d="M9 0C4.02943 0 0 4.02943 0 9C0 13.9706 4.02943 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02943 13.9706 0 9 0ZM5.76562 3.57495C5.95106 3.56401 6.11866 3.67485 6.24573 3.87158L7.47619 6.20506C7.60575 6.48157 7.53213 6.77765 7.33887 6.97521L6.77527 7.53881C6.74049 7.58647 6.7176 7.64016 6.71704 7.6992C6.93318 8.53583 7.5888 9.30754 8.16726 9.83824C8.74569 10.3689 9.3674 11.0875 10.1744 11.2577C10.2742 11.2855 10.3964 11.2955 10.4678 11.2291L11.1226 10.5623C11.3486 10.391 11.6755 10.3079 11.9169 10.448H11.9278L14.1482 11.7587C14.4741 11.963 14.5078 12.3578 14.2745 12.598L12.7452 14.1152C12.5194 14.3468 12.2194 14.4247 11.9279 14.425C10.6387 14.3864 9.42051 13.7537 8.41995 13.1034C6.7776 11.9086 5.2711 10.4267 4.32533 8.63636C3.96259 7.88561 3.53649 6.9277 3.57716 6.08975C3.58079 5.7745 3.66607 5.46567 3.88807 5.26248L5.41737 3.73318C5.5365 3.63181 5.65436 3.58152 5.76562 3.57495Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}

export default PhoneIcon;
