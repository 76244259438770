import React from 'react';
import { SvgIcon } from '@mui/material';

function EditIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 16 16" fill="none">
			<path
				d="M7.33325 2.66699H2.66659C2.31296 2.66699 1.97382 2.80747 1.72378 3.05752C1.47373 3.30756 1.33325 3.6467 1.33325 4.00033V13.3337C1.33325 13.6873 1.47373 14.0264 1.72378 14.2765C1.97382 14.5265 2.31296 14.667 2.66659 14.667H11.9999C12.3535 14.667 12.6927 14.5265 12.9427 14.2765C13.1928 14.0264 13.3333 13.6873 13.3333 13.3337V8.66699"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M12.3333 1.66714C12.5985 1.40193 12.9582 1.25293 13.3333 1.25293C13.7083 1.25293 14.068 1.40193 14.3333 1.66714C14.5985 1.93236 14.7475 2.29207 14.7475 2.66714C14.7475 3.04222 14.5985 3.40193 14.3333 3.66714L7.99992 10.0005L5.33325 10.6671L5.99992 8.00048L12.3333 1.66714Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default EditIcon;
