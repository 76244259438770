import React from 'react';
import { useMediaQuery } from '@mui/material';
import { Menu } from './components/Menu';

const Navbar = ({ type }) => {
	const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'));

	return !isTablet && <Menu type={type} small={isTablet} />;
};

export default Navbar;
