import React from 'react';
import { styled } from '@mui/material/styles';
import { ButtonBase } from '@mui/material';

const PrimaryButton = styled(ButtonBase)(({ theme, variant = 'default', disabled, fullwidth }) => {
	const style = {
		default: {
			color: theme.palette.base.white,
			colorDisabled: theme.palette.default.inactive,
			background: 'linear-gradient(90deg, #E7BA63 -27.18%, #DC9733 114.56%)',
			backgroundDisabled: 'rgba(164, 161, 161, 0.25)',
			backgroundHover: 'linear-gradient(90deg, #E7BA63 -12.38%, #DC9733 109.52%)',
			backgroundActive: theme.palette.gold002,
			shadowHover: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
		},
		outlined: {
			border: `1px solid ${theme.palette.gold002}`,
			borderDisabled: `1px solid ${theme.palette.gray003}`,
			color: theme.palette.gold002,
			colorDisabled: theme.palette.gray003,
			background: 'transparent',
			backgroundHover: theme.palette.gold003,
		},
		text: {
			color: theme.palette.gold002,
			colorDisabled: theme.palette.gray003,
			background: 'transparent',
			colorHover: theme.palette.base.black,
		},
	};

	return {
		borderRadius: 100,
		padding: '12px 24px',
		fontWeight: theme.typography.fontWeight.bold,
		fontSize: theme.typography.fontSize.small,
		width: fullwidth ? '100%' : '',
		maxHeight: '40px',

		border: disabled ? style[variant].borderDisabled : style[variant].border,
		color: disabled ? style[variant].colorDisabled : style[variant].color,
		background: disabled ? style[variant].backgroundDisabled : style[variant].background,
		'&:hover': {
			color: style[variant].colorHover,
			background: style[variant].backgroundHover,
			boxShadow: style[variant].shadowHover,
		},
		'&:active': {
			background: style[variant].backgroundActive,
		},
	};
});

export const Button = props => <PrimaryButton {...props} />;
