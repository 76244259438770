import useUser from 'hooks/useUser';
import ReactTimeAgo from 'react-time-ago';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDetails } from 'utils/utils';
import { Grid, Stack, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { Button, CreateTask, MiniTable } from 'components';
import { TASK_PRIORITY } from 'constants/general';
import { AddIcon } from 'assets/icons';
import { uniqueId } from 'lodash';

const Indicator = styled('div')(({ theme, color }) => ({
	width: 8,
	height: 8,
	borderRadius: '50%',
	backgroundColor: color,
}));

export const AccountantHome = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const { user } = useUser();
	const id = user.id;

	const [openTask, setOpenTask] = useState(false);
	const [fetch, setFetch] = useState('loading');

	const { details: tasks } = useDetails(`/tasks/list?pageSize=5&employeeId=${id}`, [], [fetch]);

	useEffect(() => {
		if (id) {
			setFetch(uniqueId());
		}
	}, [id]);

	const indicateColor = value => {
		return {
			[TASK_PRIORITY.LOW]: theme.palette.default.success,
			[TASK_PRIORITY.MEDIUM]: theme.palette.default.warning,
			[TASK_PRIORITY.HIGH]: theme.palette.default.error,
		}[value];
	};

	return (
		<>
			<Button onClick={() => setOpenTask(true)} sx={{ mt: 3 }}>
				<AddIcon sx={{ fontSize: 21, mr: 1 }} />
				{t('CreateTask')}
			</Button>

			<Grid container spacing={2} sx={{ mt: 2 }}>
				<Grid item xs={12} md={6}>
					<MiniTable
						title={t('MyTasks')}
						link={{ label: t('AllTasks'), url: '/tasks' }}
						head={[t('Title'), t('Type'), t('EndDate')]}>
						{tasks?.data?.map((item, idx) => (
							<TableRow key={idx}>
								<TableCell component="td" scope="row">
									<Stack direction="row" alignItems="center">
										<Indicator color={indicateColor(item.priority || 'medium')} sx={{ mr: '4px' }} />
										{item.name}
									</Stack>
								</TableCell>
								<TableCell>{item.type || '-'}</TableCell>
								<TableCell>
									<ReactTimeAgo date={new Date(item.endDate)} locale={i18n.language} />
								</TableCell>
							</TableRow>
						))}
					</MiniTable>
				</Grid>
			</Grid>

			<CreateTask
				title={t('NewTask')}
				url="employee"
				open={openTask}
				onClose={() => setOpenTask(false)}
				onSuccess={() => setFetch(uniqueId())}
			/>
		</>
	);
};
