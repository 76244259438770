import React from 'react';
import { SvgIcon } from '@mui/material';

function DocumentIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 21 22" fill="none">
			<path
				fill="none"
				d="M12.25 2.25H5.25C4.78587 2.25 4.34075 2.43437 4.01256 2.76256C3.68437 3.09075 3.5 3.53587 3.5 4V18C3.5 18.4641 3.68437 18.9092 4.01256 19.2374C4.34075 19.5656 4.78587 19.75 5.25 19.75H15.75C16.2141 19.75 16.6592 19.5656 16.9874 19.2374C17.3156 18.9092 17.5 18.4641 17.5 18V7.5L12.25 2.25Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path fill="none" d="M12.25 2.25V7.5H17.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path fill="none" d="M14 11.875H7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path fill="none" d="M14 15.375H7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path fill="none" d="M8.75 8.375H7.875H7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</SvgIcon>
	);
}

export default DocumentIcon;
