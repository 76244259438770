import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDetails, defaultDateFormat } from 'utils/utils';
import { BasicTable, Button, CreateService } from 'components';
import { TableCell, TableRow } from '@mui/material';
import { DEFAULT_PAGE_SIZE, STATE, STATE_OPTIONS } from 'constants/general';
import qs from 'query-string';
import { debounce, uniqueId } from 'lodash';
import { AddIcon } from 'assets/icons';

const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.h2,
	fontWeight: theme.typography.fontWeight.bold,
}));
const StatusIndicator = styled('p')(({ theme, state }) => {
	const color = {
		[STATE.NEW]: theme.palette.default.link,
		[STATE.ONGOING]: theme.palette.default.warning,
		[STATE.FINISHED]: theme.palette.default.success,
	};

	return {
		margin: 0,
		textTransform: 'lovercase',
		color: color[state],
	};
});

export const ClientServices = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [openService, setOpenService] = useState(false);
	const [page, setPage] = useState(1);
	const [title, setTitle] = useState('');
	const [typeId, setTypeId] = useState('');
	const [state, setState] = useState('');
	const [fetch, setFetch] = useState('');

	const query = qs.stringify({ page, pageSize: DEFAULT_PAGE_SIZE, title, typeId, state });
	const { details: services } = useDetails(`/orders?${query}`, [], [fetch, page, title, typeId, state]);

	const handlePageChange = (e, page) => {
		setPage(page);
	};

	const serviceSearch = useMemo(() => debounce(e => setTitle(e.target.value), 500), []);
	const taskTypeSelect = e => setTypeId(e.target.value);
	const stateSelect = e => setState(e.target.value);

	const filters = [
		{ type: 'search', name: 'title', onChange: serviceSearch },
		{
			type: 'select',
			options: [{ label: 'Type', value: 'type' }],
			field: { value: typeId, onChange: taskTypeSelect },
		},
		{
			type: 'select',
			options: STATE_OPTIONS,
			field: { value: state, onChange: stateSelect },
		},
		{ type: 'none' },
		{ type: 'none' },
	];

	return (
		<>
			<Title sx={{ mt: 3, ml: 1 }}>{t('Services')}</Title>
			<Button onClick={() => setOpenService(true)} sx={{ mt: 1 }}>
				<AddIcon sx={{ fontSize: 21, mr: 1 }} />
				{t('OrderService')}
			</Button>

			<BasicTable
				sx={{ mt: 5 }}
				head={[t('ServiceTitle'), t('TaskType'), t('State'), t('CreateDate'), t('EndDate')]}
				filters={filters}
				page={page}
				pageCount={services?.pageCount}
				onPageChange={handlePageChange}>
				{services?.data?.map(item => (
					<TableRow key={item.id} onClick={() => navigate('/services/' + item.id)} sx={{ cursor: 'pointer' }}>
						<TableCell>{item.title}</TableCell>
						<TableCell>{item.type}</TableCell>
						<TableCell>
							<StatusIndicator state={item.state}>{t(item.state)}</StatusIndicator>
						</TableCell>
						<TableCell>{defaultDateFormat(item.startDate)}</TableCell>
						<TableCell>{defaultDateFormat(item.endDate)}</TableCell>
					</TableRow>
				))}
			</BasicTable>

			<CreateService
				title={t('ServiceRequest')}
				open={openService}
				onClose={() => setOpenService(false)}
				onSuccess={() => setFetch(uniqueId())}
			/>
		</>
	);
};
