import React from 'react';
import { styled } from '@mui/system';
import { Tooltip } from '@mui/material';

const TooltipValue = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.xs,
	fontWeight: theme.typography.fontWeight.r,
}));

export const TableTooltip = ({ label, optionLabel, values = [] }) => {
	const multiple = values.length > 1;

	return (
		<>
			{multiple ? (
				<Tooltip
					title={values.map((item, idx) => (
						<TooltipValue key={idx}>{item[optionLabel]}</TooltipValue>
					))}
					placement="top">
					<span>{label} ...</span>
				</Tooltip>
			) : (
				`${values[0]?.[optionLabel] || ''}`
			)}
		</>
	);
};
