import React from 'react';
import { styled } from '@mui/system';
import { useMediaQuery } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
	width: '100%',
	minHeight: '100px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	backgroundColor: 'transparent',
	marginTop: 'auto',
}));

const Footer = () => {
	const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

	return (
		<Container
			sx={{ alignItems: 'center', flexDirection: isTablet ? 'column' : 'row' }}
		></Container>
	);
};

export default Footer;
