import api from 'api/axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Toast } from 'components/Toast/Toast';
import { toast } from 'react-toastify';
import { ALERT } from 'constants/general';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';

export const hextorgb = (hex = '#000000', opacity = 1) => {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);
	if (opacity) {
		return `rgba(${r}, ${g}, ${b}, ${opacity})`;
	}
	return `rgb(${r}, ${g}, ${b})`;
};

export const passwordStrength = password => /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&]{8,}$/.test(password);

export const removeSpaces = value => value.replace(/ /g, '');

export const showToast = ({ title = '', text = '', type = ALERT.INFO } = {}) => {
	const toastId = uniqueId();
	toast(<Toast sx={{ mt: 2 }} timeout={5000} toastId={toastId} message={{ title, text, type }} />, {
		toastId,
	});
};

export const removeJWT = () => {
	localStorage.removeItem('jwt');
	sessionStorage.removeItem('jwt');
};

export const useDetails = (path, initial, triggers = [], error = { title: 'SomethingWrongTryAgain', text: '', type: 'error' }) => {
	const { t } = useTranslation();

	const [details, setDetails] = useState(initial);

	async function fetchDetails() {
		try {
			const res = await api.get(path);
			setDetails(res.data);
		} catch (err) {
			showToast({
				title: t(err.title || error.title),
				text: t(err.text || error.text),
				type: err.type || error.type,
			});
		}
	}

	useEffect(() => {
		if (triggers.some(trigger => trigger === 'loading' || (trigger !== '' && trigger !== 0 && !trigger))) {
			return;
		}

		fetchDetails();
	}, triggers); // eslint-disable-line react-hooks/exhaustive-deps

	return {
		details,
		setDetails,
		fetchDetails,
	};
};

export const defaultDateFormat = date => (!date ? '-' : moment(date).format('YYYY-MM-DD'));
export const defaultDateTimeFormat = date => (!date ? '-' : moment(date).format('YYYY-MM-DD HH:mm'));
export const formatToHours = minutes => moment('1900-01-01T00:00:00').set('minute', minutes).format('HH:mm');
export const formatToMinutes = time => moment(time).hour() * 60 + moment(time).minute();

export const generateTime = ({ from, to, interval, shift }) => {
	const result = Array.from(new Array((to - from) / interval)).map((_, index) => {
		const minutes = (shift ? index + 1 : index) * interval;
		const label = formatToHours(minutes);

		return { label: minutes === 1440 ? '24:00' : label, value: minutes };
	});

	return result;
};
