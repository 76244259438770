import api from 'api/axios';
import React, { useState, useMemo } from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDetails, showToast } from 'utils/utils';
import { Modal, Button, AddClient, BasicTable, TableTooltip, MenuDropdown } from 'components';
import { Grid, Box, TableCell, TableRow, MenuItem, useMediaQuery } from '@mui/material';
import { DEFAULT_PAGE_SIZE } from 'constants/general';
import { AddIcon } from 'assets/icons';
import qs from 'query-string';
import { debounce, uniqueId } from 'lodash';

const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.h2,
	fontWeight: theme.typography.fontWeight.bold,
}));

export const Clients = () => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [openClient, setOpenClient] = useState(false);
	const [page, setPage] = useState(1);
	const [companyName, setCompanyName] = useState('');
	const [companyCode, setCompanyCode] = useState('');
	const [employeeId, setEmployee] = useState('');
	const [email, setEmail] = useState('');
	const [fetch, setFetch] = useState('');
	const [itemDelete, setItemDelete] = useState('');

	const query = qs.stringify({ page, pageSize: DEFAULT_PAGE_SIZE, companyName, companyCode, employeeId, email });
	const { details: clients } = useDetails(`/clients/list?${query}`, [], [fetch, page, companyName, companyCode, employeeId, email]);
	const { details: employees } = useDetails('/employees/list/light', []);

	const handlePageChange = (e, page) => {
		setPage(page);
	};
	const handleClick = item => {
		navigate('/clients/' + item.id);
	};
	const handleDelete = async id => {
		try {
			await api.delete(`/clients/${id}`);
			setFetch(uniqueId());
			setItemDelete('');

			showToast({
				title: t('DeleteClientSuccess'),
				type: 'success',
			});
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const companyNameSearch = useMemo(() => debounce(e => setCompanyName(e.target.value), 500), []);
	const companyCodeSearch = useMemo(() => debounce(e => setCompanyCode(e.target.value), 500), []);
	const emailSearch = useMemo(() => debounce(e => setEmail(e.target.value), 500), []);

	const filters = [
		{ type: 'search', name: 'companyName', onChange: companyNameSearch },
		{ type: 'search', name: 'companyCode', onChange: companyCodeSearch },
		{
			type: 'select',
			options: [{ name: 'All', id: 'all' }, ...employees],
			field: { value: employeeId, onChange: e => setEmployee(e.target.value) },
			optionLabel: 'name',
			optionValue: 'id',
		},
		{ type: 'search', name: 'email', onChange: emailSearch },
		{ type: 'none' },
	];

	return (
		<>
			<Title sx={{ mt: 3, ml: 1 }}>{t('Clients')}</Title>
			<Button onClick={() => setOpenClient(true)} sx={{ mt: 1, width: isMobile ? '100%' : 'auto' }}>
				<AddIcon sx={{ fontSize: 21, mr: 1 }} />
				{t('AddClient')}
			</Button>

			<AddClient
				title={t('NewClient')}
				open={openClient}
				onClose={() => setOpenClient(false)}
				onSuccess={() => setFetch(uniqueId())}
			/>

			<BasicTable
				sx={{ mt: 5 }}
				head={[t('ClientTitle'), t('CompanyCode'), t('AssignedEmployee'), t('Email'), t('Actions')]}
				filters={filters}
				page={page}
				pageCount={clients?.pageCount}
				onPageChange={handlePageChange}>
				{clients?.data?.map(item => (
					<TableRow key={item.id}>
						<TableCell onClick={() => handleClick(item)}>{item.companyName}</TableCell>
						<TableCell onClick={() => handleClick(item)}>{item.companyCode}</TableCell>
						<TableCell onClick={() => handleClick(item)}>
							<TableTooltip label={item.assignedEmployees[0]?.name} optionLabel={'name'} values={item.assignedEmployees} />
						</TableCell>
						<TableCell>{item.email}</TableCell>
						<TableCell align="right">
							<MenuDropdown>
								<MenuItem onClick={() => handleClick(item)}>{t('Preview')}</MenuItem>
								<MenuItem onClick={() => setItemDelete(item.id)}>{t('Delete')}</MenuItem>
							</MenuDropdown>
						</TableCell>
					</TableRow>
				))}
			</BasicTable>

			<Modal title={t('AreYouSureToDelete') + '?'} open={!!itemDelete} onClose={() => setItemDelete('')} width="40%">
				<Box sx={{ mt: 3, textAlign: 'right' }}>
					<Button variant="text" sx={{ color: 'gray' }} onClick={() => setItemDelete('')}>
						{t('Cancel')}
					</Button>
					<Button variant="outlined" onClick={() => handleDelete(itemDelete)}>
						{t('Delete')}
					</Button>
				</Box>
			</Modal>
		</>
	);
};
