import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Stack, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const Container = styled('div')(({ theme }) => ({
	padding: '16px 16px 16px 24px',
	borderRadius: 8,
	backgroundColor: theme.palette.base.white001,
	height: '100%',
}));
const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const StyledLink = styled(Link)(({ theme }) => ({
	textDecoration: 'none',
	color: theme.palette.gold002,
	fontSize: theme.typography.fontSize.xs,
	fontWeight: theme.typography.fontWeight.bold,
}));
const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
	'& th:first-of-type': {
		borderRadius: '8px 0 0 8px',
	},
	'& th:last-of-type': {
		borderRadius: '0 8px 8px 0',
	},
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.base.background,
		fontWeight: theme.typography.fontWeight.bold,
		fontSize: theme.typography.fontSize.xs,
		padding: '11px 16px',
		borderBottom: 'none',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: theme.typography.fontSize.small,
	},
	borderBottom: '1px solid #DDDDDD',
}));
const StyledTableBody = styled(TableBody)(({ theme }) => ({
	fontSize: theme.typography.fontSize.small,
	'tr:last-child': {
		'td, th': {
			borderBottom: 0,
		},
	},
}));
const StyledCaption = styled('caption')(({ theme }) => ({
	textAlign: 'center !important',
	fontSize: theme.typography.fontSize.small,
}));

export const MiniTable = ({ title, link: { label, url }, head = [], children }) => {
	const { t } = useTranslation();

	return (
		<Container>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
				<Title>{title}</Title>
				<StyledLink to={url}>{label}</StyledLink>
			</Stack>

			<TableContainer>
				<Table>
					<TableHead>
						<StyledTableHeadRow>
							{head.map((item, index) => (
								<StyledTableCell key={index}>{item}</StyledTableCell>
							))}
						</StyledTableHeadRow>
					</TableHead>
					{children?.length > 0 ? <StyledTableBody>{children}</StyledTableBody> : <StyledCaption>{t('NoData')}</StyledCaption>}
				</Table>
			</TableContainer>
		</Container>
	);
};
