import api from 'api/axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from 'utils/utils';
import { Modal, Button, SearchDropdownSelect } from 'components';
import { Box, Grid } from '@mui/material';

export const TransferTask = ({ task = {}, title, open, onClose, onSuccess }) => {
	const { t } = useTranslation();

	const [employees, setEmployees] = useState([]);

	const resetState = () => {
		setEmployees([]);
	};

	useEffect(() => {
		if (open === false) {
			resetState();
		}
	}, [open]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (task?.id) {
			const employeesObj = task.employeeIds?.map(item => ({ id: item }));

			setEmployees(employeesObj);
		}
	}, [task?.id, task?.employeeIds]);

	const updateTask = async () => {
		const employeeIds = employees.map(item => item.id);

		try {
			await api.put(`/tasks/${task?.id}`, { employeeIds });
			resetState();
			onSuccess();
			onClose();

			showToast({
				title: t('TransferTaskSuccess'),
				type: 'success',
			});
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	return (
		<Modal title={title} open={open} onClose={onClose} width="40%">
			<Grid container>
				<Grid item xs={12} md={12}>
					<SearchDropdownSelect
						onChange={options => setEmployees(options)}
						values={employees}
						initialValues={task.employeeIds}
						placeholder={t('SearchEmployee')}
						optionLabel="name"
						optionSublabel="position"
						url="/employees/list/light"
						type="user"
						multiple
					/>
				</Grid>
			</Grid>

			<Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
				<Button type="button" onClick={() => updateTask()} sx={{ mt: 5 }}>
					{t('Transfer')}
				</Button>
			</Box>
		</Modal>
	);
};

TransferTask.propTypes = {
	task: PropTypes.object,
	title: PropTypes.string,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
};
