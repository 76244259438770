import * as Yup from 'yup';
// import { passwordStrength } from 'utils/utils';

export const authSchema = {
	email: Yup.string().email('InvalidEmailFormat').required('Required'),
	password: Yup.string().required('PasswordRequired'),
	// .test('weak-password', t('WeakPassword'), function (password) { TODO: uncomment when ready to production
	// 	return passwordStrength(password);
	// }),
	repeatPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'PasswordsMustMatch')
		.required('RepeatPasswordRequired'),
	// .test('weak-password', t('WeakPassword'), function (password) { TODO: uncomment when ready to production
	// 	return passwordStrength(password);
	// }),
};
