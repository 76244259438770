import api from 'api/axios';
import useUser from 'hooks/useUser';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown } from './Dropdown';
import { useMediaQuery } from '@mui/material';
import { UserIcon, CompanyIcon, LogoutIcon, ChevronIcon } from 'assets/icons';

const Button = styled('button')(() => ({
	border: 'none',
	backgroundColor: 'transparent',
	padding: '8px 24px 8px 16px',
	borderRadius: 16,
	display: 'flex',
	alignItems: 'center',
	height: 58,
	cursor: 'pointer',
}));
const Avatar = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.gray002,
	width: 40,
	height: 40,
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
const User = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-start',
}));
const Name = styled('p')(({ theme }) => ({
	margin: 0,
	textAlign: 'left',
	color: theme.palette.base.black,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const JobTitle = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.gray003,
	fontSize: theme.typography.fontSize.xs,
	fontWeight: theme.typography.fontWeight.bold,
}));
const MenuContainer = styled('div')(() => ({
	padding: '10px 24px 18px 24px',
	minWidth: '256px',
}));
const Menu = styled('div')(() => ({
	margin: '0 0 0 20px',
	display: 'flex',
	flexDirection: 'column',
}));
const MenuItem = styled(Link)(({ theme }) => ({
	textDecoration: 'none',
	margin: '16px 0',
	display: 'flex',
	alignItems: 'center',
	color: theme.palette.base.black001,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.m,
	'& svg': {
		width: '20px',
		height: '20px',
		marginRight: '20px',
	},
}));

const UserMenu = ({ type }) => {
	const { t } = useTranslation();
	const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'));
	const { user } = useUser();
	const { setAuth } = useAuth();

	const [close, setClose] = useState(false);

	const handleSelect = () => {
		setClose(!close);
	};

	const logout = async () => {
		setClose(!close);
		setAuth({});
		await api.post('/logout');
	};

	const renderJobTitle = type => {
		return {
			admin: 'Administrator',
			accountant: 'Accountant',
			none: '',
		}[type];
	};

	const UserButton = ({ type = 'none' }) => (
		<Button>
			<Avatar sx={{ mr: 2 }}>{type === 'client' ? <CompanyIcon sx={{ fontSize: 21 }} /> : <UserIcon sx={{ fontSize: 21 }} />}</Avatar>
			{!isTablet && (
				<User sx={{ mr: 2 }}>
					<Name>{user?.name}</Name>
					<JobTitle className="dynamic-text">{t(renderJobTitle(type))}</JobTitle>
				</User>
			)}
			<ChevronIcon className="arrow" sx={{ fontSize: 10 }} />
		</Button>
	);

	const UserMenu = ({ type = 'admin' }) => (
		<MenuContainer>
			<Menu>
				{type === 'client' && (
					<MenuItem to="/profile" onClick={handleSelect}>
						<UserIcon />
						{t('Profile')}
					</MenuItem>
				)}
				<MenuItem to="/login" onClick={logout}>
					<LogoutIcon />
					{t('LogOut')}
				</MenuItem>
			</Menu>
		</MenuContainer>
	);

	return <Dropdown button={<UserButton type={type} />} menuContent={<UserMenu type={type} />} onClose={close} />;
};

export default UserMenu;
