import React from 'react';
import { SvgIcon } from '@mui/material';

function FeatherIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 21 22" fill="none">
			<path
				fill="none"
				d="M17.71 11.21C18.6951 10.2249 19.2485 8.88876 19.2485 7.49559C19.2485 6.10243 18.6951 4.76633 17.71 3.78122C16.7249 2.79611 15.3888 2.24268 13.9956 2.24268C12.6025 2.24268 11.2664 2.79611 10.2812 3.78122L4.375 9.68747V17.125H11.8125L17.71 11.21Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path fill="none" d="M14 7.5L1.75 19.75" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path fill="none" d="M15.3125 13.625H7.875" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</SvgIcon>
	);
}

export default FeatherIcon;
