import * as React from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Pagination } from './components/Pagination';
import { FilterSelect } from './components/FilterSelect';
import { FilterInputSearch } from './components/FilterInputSearch';

const Container = styled(Paper)(({ theme }) => ({
	borderRadius: '8px',
	backgroundColor: theme.palette.base.white001,
}));
const StyledTableRowUp = styled(TableRow)(({ theme }) => ({
	backgroundColor: theme.palette.base.background,
	'& th:first-of-type': {
		borderRadius: '8px 0 0 0',
	},
	'& th:last-of-type': {
		borderRadius: '0 8px 0 0',
	},
}));
const StyledTableRowDown = styled(TableRow)(({ theme }) => ({
	backgroundColor: theme.palette.base.background,

	'& th:first-of-type': {
		borderRadius: '0 0 0 8px',
	},
	'& th:last-of-type': {
		borderRadius: '0 0 8px 0',
	},
}));
const StyledTableBody = styled(TableBody)(({ theme }) => ({
	'.MuiTableRow-root': {
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: theme.palette.gold003,
		},
	},
}));
const StyledCaption = styled('caption')(({ theme }) => ({
	textAlign: 'center !important',
}));

export const BasicTable = ({ head = [], filters = [], children, pageCount, page = 1, onPageChange, ...props }) => {
	const { t } = useTranslation();

	const renderFilterType = (type, filter) => {
		return {
			select: (
				<FilterSelect
					options={filter.options}
					field={filter.field}
					optionLabel={filter.optionLabel}
					optionValue={filter.optionValue}
				/>
			),
			search: <FilterInputSearch name={filter.name} onChange={filter.onChange} />,
			none: '',
		}[type];
	};

	return (
		<Container elevation={0} sx={{ pl: '12px', pr: '12px', pt: 2, pb: 4, ...props.sx }}>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<StyledTableRowUp>
							{head.map((item, index) => (
								<TableCell key={index}>{item}</TableCell>
							))}
						</StyledTableRowUp>
						<StyledTableRowDown>
							{filters?.map((item, index) => (
								<TableCell key={index} sx={{ borderBottom: 'none' }}>
									{renderFilterType(item.type, item)}
								</TableCell>
							))}
						</StyledTableRowDown>
					</TableHead>
					{children?.length > 0 ? <StyledTableBody>{children}</StyledTableBody> : <StyledCaption>{t('NoData')}</StyledCaption>}
				</Table>
			</TableContainer>
			<Pagination count={pageCount} page={page} onChange={onPageChange} />
		</Container>
	);
};
