import * as React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { MoreVerticalIcon } from 'assets/icons';

const StyledButton = styled(Button)(({ theme }) => ({
	color: theme.palette.gray003,
	minWidth: '20px',
}));

export const MenuDropdown = ({ children }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<StyledButton id="table-action-button" onClick={handleClick}>
				<MoreVerticalIcon sx={{ fontSize: '17px' }} />
			</StyledButton>
			<Menu
				id="table-action-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				{children}
			</Menu>
		</div>
	);
};
