import api from 'api/axios';
import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDetails, showToast } from 'utils/utils';
import { Modal, Button, CreateTask, BasicTable, TableTooltip, MenuDropdown } from 'components';
import { Box, Stack, TableCell, TableRow, MenuItem } from '@mui/material';
import { TASK_PRIORITY, DEFAULT_PAGE_SIZE, STATE, STATE_OPTIONS } from 'constants/general';
import { AddIcon } from 'assets/icons';
import qs from 'query-string';
import { debounce, uniqueId } from 'lodash';

const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.h2,
	fontWeight: theme.typography.fontWeight.bold,
}));
const PriorityIndicator = styled('div')(({ theme, priority }) => {
	const color = {
		[TASK_PRIORITY.HIGH]: theme.palette.default.error,
		[TASK_PRIORITY.MEDIUM]: theme.palette.default.warning,
		[TASK_PRIORITY.LOW]: theme.palette.default.success,
	};

	return {
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		background: color[priority],
		marginRight: '4px',
	};
});
const StatusIndicator = styled('p')(({ theme, state }) => {
	const color = {
		[STATE.NEW]: theme.palette.default.link,
		[STATE.ONGOING]: theme.palette.default.warning,
		[STATE.FINISHED]: theme.palette.default.success,
	};

	return {
		margin: 0,
		textTransform: 'lovercase',
		color: color[state],
	};
});

export const Tasks = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [openTask, setOpenTask] = useState(false);
	const [page, setPage] = useState(1);
	const [name, setName] = useState('');
	const [typeId, setTypeId] = useState('');
	const [companyId, setCompanyId] = useState('');
	const [employeeId, setEmployeeId] = useState('');
	const [state, setState] = useState('');
	const [fetch, setFetch] = useState('');
	const [itemDelete, setItemDelete] = useState('');

	const query = qs.stringify({ page, pageSize: DEFAULT_PAGE_SIZE, name, typeId, companyId, employeeId, state });
	const { details: tasks } = useDetails(`/admin/tasks?${query}`, [], [fetch, page, name, typeId, companyId, employeeId, state]);
	const { details: clients } = useDetails('/clients/list/light', []);
	const { details: employees } = useDetails('/employees/list/light', []);

	const handlePageChange = (e, page) => {
		setPage(page);
	};
	const handleClick = item => {
		navigate('/tasks/' + item.id);
	};
	const handleDelete = async id => {
		try {
			await api.delete(`/admin/tasks/${id}`);
			setFetch(uniqueId());
			setItemDelete('');

			showToast({
				title: t('DeleteTaskSuccess'),
				type: 'success',
			});
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const taskSearch = useMemo(() => debounce(e => setName(e.target.value), 500), []);
	const taskTypeSelect = e => setTypeId(e.target.value);
	const companySelect = e => setCompanyId(e.target.value);
	const employeeSelect = e => setEmployeeId(e.target.value);
	const stateSelect = e => setState(e.target.value);

	const filters = [
		{ type: 'search', name: 'name', onChange: taskSearch },
		{
			type: 'select',
			options: [{ label: 'Type', value: 'type' }],
			field: { value: typeId, onChange: taskTypeSelect },
		},
		{
			type: 'select',
			options: [{ companyName: 'All', id: '' }, ...clients],
			field: { value: companyId, onChange: companySelect },
			optionLabel: 'companyName',
			optionValue: 'id',
		},
		{
			type: 'select',
			options: [{ name: 'All', id: '' }, ...employees],
			field: { value: employeeId, onChange: employeeSelect },
			optionLabel: 'name',
			optionValue: 'id',
		},
		{
			type: 'select',
			options: STATE_OPTIONS,
			field: { value: state, onChange: stateSelect },
		},
		{ type: 'none' },
	];

	return (
		<>
			<Title sx={{ mt: 3, ml: 1 }}>{t('Tasks')}</Title>
			<Button onClick={() => setOpenTask(true)} sx={{ mt: 1 }}>
				<AddIcon sx={{ fontSize: 21, mr: 1 }} />
				{t('CreateTask')}
			</Button>

			<CreateTask title={t('NewTask')} open={openTask} onClose={() => setOpenTask(false)} onSuccess={() => setFetch(uniqueId())} />

			<BasicTable
				sx={{ mt: 5 }}
				head={[t('TaskTitle'), t('TaskType'), t('Client'), t('AssignedEmployee'), t('State'), t('Actions')]}
				filters={filters}
				page={page}
				pageCount={tasks?.pageCount}
				onPageChange={handlePageChange}>
				{tasks?.data?.map(item => (
					<TableRow key={item.id}>
						<TableCell onClick={() => handleClick(item)}>
							<Stack direction="row" alignItems="center">
								<PriorityIndicator priority={item.priority} />
								{item.name}
							</Stack>
						</TableCell>
						<TableCell onClick={() => handleClick(item)}>'Tipas'</TableCell>
						<TableCell onClick={() => handleClick(item)}>{item.company}</TableCell>
						<TableCell onClick={() => handleClick(item)}>
							{item.employees?.length > 0 ? (
								<TableTooltip label={item.employees[0].name} optionLabel={'name'} values={item.employees} />
							) : (
								''
							)}
						</TableCell>
						<TableCell onClick={() => handleClick(item)}>
							<StatusIndicator state={item.state}>{t(item.state)}</StatusIndicator>
						</TableCell>
						<TableCell align="right">
							<MenuDropdown>
								<MenuItem onClick={() => setItemDelete(item.id)}>{t('Delete')}</MenuItem>
							</MenuDropdown>
						</TableCell>
					</TableRow>
				))}
			</BasicTable>

			<Modal title={t('AreYouSureToDelete') + '?'} open={!!itemDelete} onClose={() => setItemDelete('')} width="40%">
				<Box sx={{ mt: 3, textAlign: 'right' }}>
					<Button variant="text" sx={{ color: 'gray' }} onClick={() => setItemDelete('')}>
						{t('Cancel')}
					</Button>
					<Button variant="outlined" onClick={() => handleDelete(itemDelete)}>
						{t('Delete')}
					</Button>
				</Box>
			</Modal>
		</>
	);
};
