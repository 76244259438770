import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Drawer, Box, useMediaQuery, Divider } from '@mui/material';
import { BurgerIcon } from 'assets/icons';
import UserMenu from './components/UserMenu';
import { Menu } from '../Navbar/components/Menu';
import LanguageSelect from './components/LanguageSelect';
// import Notifications from './components/Notifications';

const Container = styled('div')(({ theme }) => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	backgroundColor: theme.palette.base.background,
	position: 'relative',
	'&:after': {
		content: '""',
		position: 'absolute',
		left: 16,
		right: 24,
		bottom: 0,
		height: '1px',
		borderBottom: '1px solid #ECECEC',
	},
}));
const HeaderMenu = styled('div')(() => ({
	display: 'flex',
	alignItems: 'center',
}));
const MenuButton = styled('button')(({ theme }) => ({
	border: 0,
	background: 'unset',
	cursor: 'pointer',
	color: theme.palette.base.black002,
}));

const Header = ({ type }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'));
	const [open, setOpen] = useState(false);

	const toggleDrawer = open => event => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setOpen(open);
	};

	return (
		<Container sx={{ pt: 2, pb: 2, pl: 2, pr: isMobile ? 1 : 2 }}>
			{isTablet ? (
				<MenuButton onClick={toggleDrawer(true)} sx={{ p: 2 }}>
					<BurgerIcon sx={{ fontSize: 24 }} />
				</MenuButton>
			) : (
				<div></div>
			)}
			<HeaderMenu>
				<LanguageSelect />
				{/* <Notifications /> */}
				<Divider orientation="vertical" />
				<UserMenu type={type} />
			</HeaderMenu>

			<Drawer anchor={'left'} open={open} onClose={toggleDrawer(false)}>
				<Box sx={{ width: '100%' }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
					<Menu small={false} type={type} />
				</Box>
			</Drawer>
		</Container>
	);
};

export default Header;
