import ReactTimeAgo from 'react-time-ago';
import React, { useState } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDetails } from 'utils/utils';
import { Grid, Stack, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { Button, AddEmployee, AddClient, CreateTask, MiniTable } from 'components';
import { TASK_PRIORITY } from 'constants/general';
import { AddIcon } from 'assets/icons';
import { uniqueId } from 'lodash';

const Indicator = styled('div')(({ theme, color }) => ({
	width: 8,
	height: 8,
	borderRadius: '50%',
	backgroundColor: color,
}));

export const Home = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const [openEmployee, setOpenEmployee] = useState(false);
	const [openClient, setOpenClient] = useState(false);
	const [openTask, setOpenTask] = useState(false);
	const [fetch, setFetch] = useState('');

	const { details: tasks } = useDetails('/admin/tasks?pageSize=5', [], [fetch]);
	const { details: orders } = useDetails('/orders', [], []);

	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

	const indicateColor = value => {
		return {
			[TASK_PRIORITY.LOW]: theme.palette.default.success,
			[TASK_PRIORITY.MEDIUM]: theme.palette.default.warning,
			[TASK_PRIORITY.HIGH]: theme.palette.default.error,
		}[value];
	};

	return (
		<>
			<Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between" spacing={2} sx={{ mt: 3 }}>
				<Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
					<Button onClick={() => setOpenEmployee(true)}>
						<AddIcon sx={{ fontSize: 21, mr: 1 }} />
						{t('AddEmployee')}
					</Button>
					<Button onClick={() => setOpenClient(true)}>
						<AddIcon sx={{ fontSize: 21, mr: 1 }} />
						{t('AddClient')}
					</Button>
				</Stack>
				<Button variant="outlined" onClick={() => setOpenTask(true)}>
					<AddIcon sx={{ fontSize: 21, mr: 1 }} />
					{t('CreateTask')}
				</Button>
			</Stack>
			<Grid container spacing={2} sx={{ mt: 4 }}>
				<Grid item xs={12} md={6}>
					<MiniTable
						title={t('Tasks')}
						link={{ label: t('AllTasks'), url: '/tasks' }}
						head={[t('Title'), t('Type'), t('EndDate')]}>
						{tasks?.data?.map((row, idx) => (
							<TableRow key={idx}>
								<TableCell component="td" scope="row">
									<Stack direction="row" alignItems="center">
										<Indicator color={indicateColor(row.priority || 'medium')} sx={{ mr: '4px' }} />
										{row.name}
									</Stack>
								</TableCell>
								<TableCell>{row.type}</TableCell>
								<TableCell>
									<ReactTimeAgo date={new Date(row.endDate)} locale={i18n.language} />
								</TableCell>
							</TableRow>
						))}
					</MiniTable>
				</Grid>
				<Grid item xs={12} md={6}>
					<MiniTable
						title={t('Orders')}
						link={{ label: t('AllOrders'), url: '/services' }}
						head={[t('Title'), t('Type'), t('EndDate')]}>
						{orders?.data?.map((row, idx) => (
							<TableRow key={idx}>
								<TableCell>{row.title}</TableCell>
								<TableCell>{row.type}</TableCell>
								<TableCell>
									<ReactTimeAgo date={new Date(row.endDate)} locale={i18n.language} />
								</TableCell>
							</TableRow>
						))}
					</MiniTable>
				</Grid>
			</Grid>
			<AddEmployee
				title={t('NewEmployee')}
				buttonTitle={t('SendInvitationToEmployee')}
				open={openEmployee}
				onClose={() => setOpenEmployee(false)}
				onSuccess={() => setOpenEmployee(false)}
			/>
			<AddClient
				title={t('NewClient')}
				open={openClient}
				onClose={() => setOpenClient(false)}
				onSuccess={() => setOpenClient(false)}
			/>
			<CreateTask title={t('NewTask')} open={openTask} onClose={() => setOpenTask(false)} onSuccess={() => setFetch(uniqueId())} />
		</>
	);
};
