import useUser from 'hooks/useUser';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDetails, defaultDateFormat } from 'utils/utils';
import { BasicTable, PageTabs, Spinner } from 'components';
import { CompanyCard } from './components/CompanyCard';
import { Grid, Box, TableCell, TableRow } from '@mui/material';
import { PAYMENT_STATUS } from 'constants/general';
import { uniqueId } from 'lodash';

const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const Breadcrumb = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.xs,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
	strong: {
		color: theme.palette.base.black002,
	},
}));
const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.base.white001,
	borderRadius: 8,
}));

const StatusIndicator = styled('p')(({ theme, state }) => {
	const color = {
		[PAYMENT_STATUS.UNPAID]: theme.palette.default.error,
		[PAYMENT_STATUS.PAID]: theme.palette.default.success,
	};

	return {
		margin: 0,
		color: color[state],
	};
});

export const ClientProfile = () => {
	const { t } = useTranslation();
	const { user } = useUser();
	const clientId = user.clientIds?.[0];

	const [loading, setLoading] = useState(true);
	const [fetch, setFetch] = useState('loading');

	const { details: client } = useDetails(`/clients/profile/${clientId}`, {}, [fetch]);
	const { details: employees } = useDetails(`/clients/employees/${clientId}`, [], [fetch]);

	useEffect(() => {
		if (clientId) {
			setFetch(uniqueId());
			setLoading(false);
		}
	}, [clientId]);

	if (loading) {
		return <Spinner size={30} />;
	}

	// const data = [
	// 	{ issueDate: '2022-11-01', state: 'unpaid', payDay: '2022-12-01', amount: '132.50' },
	// 	{ issueDate: '2022-10-01', state: 'paid', payDay: '2022-10-15', amount: '132.50' },
	// ];

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Breadcrumb sx={{ mt: 2 }}>
					{client.companyName} / <strong>{t('Main')}</strong>
				</Breadcrumb>
			</Grid>
			<Grid item xs={12} sm={3}>
				<CompanyCard company={client} assignedEmployees={employees} />
			</Grid>
			<Grid item xs={12} sm={9}>
				<PageTabs
					tabs={[
						{
							label: t('Main'),
							content: (
								<StyledBox sx={{ pt: 2, mt: 2 }}>
									<Title sx={{ pl: 2 }}>{t('InvoicesForPayment')}</Title>
									<BasicTable sx={{ mt: 2 }} head={[t('DateOfIssue'), t('State'), t('PayDay'), t('Amount')]}>
										{/* {data?.map((item, idx) => (
											<TableRow key={idx} sx={{ cursor: 'pointer' }}>
												<TableCell>{defaultDateFormat(item.issueDate)}</TableCell>
												<TableCell>
													<StatusIndicator state={item.state}>{t(item.state)}</StatusIndicator>
												</TableCell>
												<TableCell>{defaultDateFormat(item.payDay)}</TableCell>
												<TableCell sx={{ fontWeight: 'bold' }}>{item.amount} Eur</TableCell>
											</TableRow>
										))} */}
									</BasicTable>
								</StyledBox>
							),
						},
						// {
						// 	label: t('Authorizations'),
						// },
						// {
						// 	label: t('Employees'),
						// 	content: '',
						// },
					]}
				/>
			</Grid>
		</Grid>
	);
};
