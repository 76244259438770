import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

function CheckedIcon(props) {
	let stroke = '#151515';

	if (props.color) {
		stroke = props.color;
	}

	return props.checked ? (
		<SvgIcon {...props} viewBox="0 0 24 24" fill="none">
			<path
				d="M9 11L12 14L22 4"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M21 12V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	) : (
		<SvgIcon {...props} viewBox="0 0 24 24" fill="none">
			<path
				d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

CheckedIcon.propTypes = {
	checked: PropTypes.bool,
	color: PropTypes.string,
};

export default CheckedIcon;
