import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Alert, AlertTitle, Grow } from '@mui/material';
import { toast } from 'react-toastify';

const Container = styled(Alert)(({ theme }) => ({
	borderRadius: theme.shape.br1,
	boxShadow: theme.shadow.s03,
	maxWidth: 550,
	minHeight: 64,
	alignItems: 'center',
	padding: '10px 30px 10px 18px',
	'.MuiAlert-icon': {
		padding: '0',
	},
	'.MuiAlert-action': {
		padding: '0 0 0 16px',
	},
}));
const Title = styled(AlertTitle)(({ theme, color }) => ({
	color: color,
	marginBottom: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const Text = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.base.black001,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.r,
}));

export const Toast = ({ message, toastId, timeout, theme, ...props }) => {
	useEffect(() => {
		const timer =
			timeout &&
			setTimeout(() => {
				toast.dismiss(toastId);
			}, timeout);

		return () => clearTimeout(timer);
	}, [message]); // eslint-disable-line react-hooks/exhaustive-deps

	const type = message?.type || props.type || 'error';
	const title = message?.title || props.title || '';
	const text = message?.text || props.text || '';

	return (
		<Grow in={!!message} timeout={200}>
			<Container severity={type}>
				<Title>{title}</Title>
				<Text>{text}</Text>
			</Container>
		</Grow>
	);
};

Toast.propTypes = {
	toastId: PropTypes.string.isRequired,
	type: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	timeout: PropTypes.number,
	message: PropTypes.object,
};
