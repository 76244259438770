import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { TASK_PRIORITY } from 'constants/general';
import { Stack, ButtonBase } from '@mui/material';

const Button = styled(ButtonBase)(({ theme, priority, selected }) => {
	const color = {
		[TASK_PRIORITY.HIGH]: theme.palette.default.error,
		[TASK_PRIORITY.MEDIUM]: theme.palette.default.warning,
		[TASK_PRIORITY.LOW]: theme.palette.default.success,
	};

	return {
		width: '24px',
		height: '24px',
		borderRadius: '4px',
		backgroundColor: selected && '#e8e8e8',
		span: {
			borderRadius: '50%',
			background: color[priority],
			margin: '6px',
		},

		'&:hover, :focus': {
			backgroundColor: '#e8e8e8',
		},
	};
});

export const PrioritySelect = ({ value, onChange, ...props }) => {
	const [selected, setSelected] = useState(value || TASK_PRIORITY.MEDIUM);
	const handleChange = value => {
		onChange(value);
		setSelected(value);
	};

	return (
		<Stack direction="row" spacing={1} {...props}>
			<Button
				priority={TASK_PRIORITY.HIGH}
				selected={selected === TASK_PRIORITY.HIGH}
				onClick={() => handleChange(TASK_PRIORITY.HIGH)}>
				<span />
			</Button>
			<Button
				priority={TASK_PRIORITY.MEDIUM}
				selected={selected === TASK_PRIORITY.MEDIUM}
				onClick={() => handleChange(TASK_PRIORITY.MEDIUM)}>
				<span />
			</Button>
			<Button priority={TASK_PRIORITY.LOW} selected={selected === TASK_PRIORITY.LOW} onClick={() => handleChange(TASK_PRIORITY.LOW)}>
				<span />
			</Button>
		</Stack>
	);
};
