import React from 'react';
import { styled } from '@mui/system';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import Header from './Header/Header';
import Footer from './Footer/Footer';

const Container = styled('div')(({ theme }) => ({
	display: 'flex',
	backgroundColor: theme.palette.base.background,
}));

const Content = styled('div')(() => ({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
}));
const Page = styled('div')(() => ({
	minHeight: 'calc(100vh - 190px)',
}));

export const Layout = ({ type }) => {
	return (
		<Container>
			<Navbar type={type} />
			<Content>
				<Header type={type} />
				<Page sx={{ pl: 2, pr: 3 }}>
					<Outlet />
				</Page>
				<Footer />
			</Content>
		</Container>
	);
};
