import React from 'react';
import { SvgIcon } from '@mui/material';

function ChevronIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 14 8">
			<path d="M1 1L7 7L13 1" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</SvgIcon>
	);
}

export default ChevronIcon;
