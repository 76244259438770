import * as Yup from 'yup';

export const schema = {
	name: Yup.string().required('Required'),
	type: Yup.string(),
	repeat: Yup.string(),
	description: Yup.string(),
	done: Yup.bool(),
	reminder: Yup.bool(),
};
