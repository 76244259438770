import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Divider } from '@mui/material';
import { defaultDateFormat } from 'utils/utils';
import { STATE } from 'constants/general';

const Container = styled('div')(({ theme }) => ({
	borderRadius: 8,
	padding: '24px 16px',
	backgroundColor: theme.palette.base.white001,
}));
const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.base,
	fontWeight: theme.typography.fontWeight.bold,
}));
const CategoryTitle = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const TitleGray = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
}));
const Text = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.r,
}));
const Status = styled('p')(({ theme, status }) => {
	const color = {
		[STATE.NEW]: theme.palette.default.link,
		[STATE.ONGOING]: theme.palette.default.warning,
		[STATE.FINISHED]: theme.palette.default.success,
	};

	return {
		margin: 0,
		width: 'auto',
		borderRadius: '20px',
		backgroundColor: '#F8F8F8',
		fontSize: theme.typography.fontSize.small,
		fontWeight: theme.typography.fontWeight.r,
		padding: '6px 14px 6px 16px',
		color: color[status],
	};
});

export const ServiceCard = ({ service = {} }) => {
	const { t } = useTranslation();

	return (
		<Container>
			<Grid container spacing={1}>
				<Grid item xs={9} md={3} display="flex" alignItems="center">
					<Title>{service.title}</Title>
				</Grid>
				<Grid item xs={3} md={3} display="flex" alignItems="center">
					<Status status={service.state}>{t(service.state)}</Status>
				</Grid>
				<Grid item xs={6} md={3}>
					<Box>
						<TitleGray>{t('EndTime')}</TitleGray>
						<Text>{defaultDateFormat(service.endDate)}</Text>
					</Box>
				</Grid>
				<Grid item xs={6} md={3}>
					<TitleGray>{t('Type')}</TitleGray>
					{service.type}
				</Grid>

				<Grid item xs={12}>
					<Divider sx={{ my: 2 }} />
					<CategoryTitle>{t('Description')}</CategoryTitle>
					<Text>{service.description}</Text>
				</Grid>
			</Grid>
		</Container>
	);
};

ServiceCard.propTypes = {
	service: PropTypes.object.isRequired,
};
