import React from 'react';
import { SvgIcon } from '@mui/material';

function CompanyIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 21 21">
			<g>
				<path
					d="M17.5 6.125H3.5C2.5335 6.125 1.75 6.9085 1.75 7.875V16.625C1.75 17.5915 2.5335 18.375 3.5 18.375H17.5C18.4665 18.375 19.25 17.5915 19.25 16.625V7.875C19.25 6.9085 18.4665 6.125 17.5 6.125Z"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
				/>
				<path
					d="M14 18.375V4.375C14 3.91087 13.8156 3.46575 13.4874 3.13756C13.1592 2.80937 12.7141 2.625 12.25 2.625H8.75C8.28587 2.625 7.84075 2.80937 7.51256 3.13756C7.18437 3.46575 7 3.91087 7 4.375V18.375"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
				/>
			</g>
		</SvgIcon>
	);
}

export default CompanyIcon;
