import React from 'react';
import { SvgIcon } from '@mui/material';

function RackIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 21 22" fill="none">
			<path
				d="M10.5 2.25L1.75 6.625L10.5 11L19.25 6.625L10.5 2.25Z"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				stroke="currentColor"
				fill="none"
			/>
			<path
				d="M1.75 15.375L10.5 19.75L19.25 15.375"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				stroke="currentColor"
				fill="none"
			/>
			<path
				d="M1.75 11L10.5 15.375L19.25 11"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default RackIcon;
