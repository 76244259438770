import axios from 'axios';
import { API_URL } from './config';
import { removeJWT } from 'utils/utils';

const api = axios.create({
	baseURL: API_URL,
	responseType: 'json',
	// timeout: 1000,
	headers: { 'Content-Type': 'application/json' },
});

api.interceptors.request.use(
	config => {
		// Send auth token if available
		const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
		if (token != null) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	err => Promise.reject(err),
);

api.interceptors.response.use(
	function(response) {
		switch (response.status) {
			// Logout
			case 204:
				removeJWT();
				break;

			default:
				return response;
		}
	},
	function(error) {
		if (!error.response) {
			console.error(error);
			return Promise.reject(error);
		}

		switch (error.response.status) {
			// Access not allowed
			case 403:
				console.error(error.response.data.message || 'You do not have permission to perform this action');

				window.location.href = '/';
				break;

			// Bad credentials or invalid token
			case 401:
				removeJWT();
				return Promise.reject(error);

			default:
				return Promise.reject(error);
		}
	},
);

export default api;
