import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDetails, defaultDateTimeFormat } from 'utils/utils';
import { Button, BasicTable, PageTabs, AddClient, CreateTask, MenuDropdown } from 'components';
import { EmployeeCard } from './components/EmployeeCard';
import { Grid, Stack, Box, TableCell, TableRow, MenuItem, useMediaQuery } from '@mui/material';
import { TASK_PRIORITY, STATE, STATE_OPTIONS, DEFAULT_PAGE_SIZE } from 'constants/general';
import { AddIcon } from 'assets/icons';
import qs from 'query-string';
import { debounce, uniqueId } from 'lodash';

const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const Breadcrumb = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.xs,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
	strong: {
		color: theme.palette.base.black002,
	},
}));
const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.base.white001,
	borderRadius: 8,
}));
const PriorityIndicator = styled('div')(({ theme, priority }) => {
	const color = {
		[TASK_PRIORITY.HIGH]: theme.palette.default.error,
		[TASK_PRIORITY.MEDIUM]: theme.palette.default.warning,
		[TASK_PRIORITY.LOW]: theme.palette.default.success,
	};

	return {
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		background: color[priority],
		marginRight: '4px',
	};
});
const StatusIndicator = styled('p')(({ theme, state }) => {
	const color = {
		[STATE.NEW]: theme.palette.default.link,
		[STATE.ONGOING]: theme.palette.default.warning,
		[STATE.FINISHED]: theme.palette.default.success,
	};

	return {
		margin: 0,
		// textTransform: 'capitalize',
		color: color[state],
	};
});

export const Employee = () => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const { t } = useTranslation();
	const { id } = useParams();
	const [fetch, setFetch] = useState('');
	const [fetchTasks, setFetchTasks] = useState('');

	const [openClient, setOpenClient] = useState(false);
	const [openTask, setOpenTask] = useState(false);

	const [page, setPage] = useState(1);
	const [name, setName] = useState('');
	const [type, setType] = useState('');
	const [state, setState] = useState('');

	const query = qs.stringify({ page, pageSize: DEFAULT_PAGE_SIZE, name, type, state });
	const { details: tasks } = useDetails(`/employees/tasks/${id}?${query}`, [], [fetchTasks, page, name, type, state]);
	const { details: employee } = useDetails(`/employees/profile/${id}`, {}, [fetch]);
	const { details: clients } = useDetails(`/employees/clients/${id}`, [], [fetch]);

	const handlePageChange = (e, page) => {
		setPage(page);
	};
	const handleUpdate = () => {
		setFetch(uniqueId());
	};

	const taskSearch = useMemo(() => debounce(e => setName(e.target.value), 500), []);
	const taskTypeSelect = e => setType(e.target.value);

	const filters = [
		{ type: 'search', name: 'name', onChange: taskSearch },
		{
			type: 'select',
			options: [{ label: 'Type', value: 'type' }],
			field: { value: type, onChange: taskTypeSelect },
		},
		{
			type: 'select',
			options: STATE_OPTIONS,
			field: { value: state, onChange: e => setState(e.target.value) },
		},
		{ type: 'none' },
	];

	const renderActionButtons = show =>
		show && (
			<Stack direction={isMobile ? 'column' : 'row'} spacing={2} sx={{ my: 3 }}>
				<Button onClick={() => setOpenClient(true)}>
					<AddIcon sx={{ fontSize: 21, mr: 1 }} />
					{t('AddClient')}
				</Button>
				<Button variant="outlined" onClick={() => setOpenTask(true)}>
					<AddIcon sx={{ fontSize: 21, mr: 1 }} />
					{t('CreateTask')}
				</Button>
			</Stack>
		);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Breadcrumb sx={{ mt: 2 }}>
					{t('Employees')} / {employee.name} / <strong>{t('Main')}</strong>
				</Breadcrumb>
			</Grid>
			<Grid item xs={12}>
				{renderActionButtons(isMobile)}
			</Grid>
			<Grid item xs={12} sm={3}>
				<EmployeeCard employee={employee} assignedClients={clients} onUpdate={handleUpdate} />
			</Grid>
			<Grid item xs={12} sm={9}>
				<PageTabs
					tabs={[
						{
							label: t('Tasks'),
							content: (
								<>
									{renderActionButtons(!isMobile)}

									<StyledBox sx={{ pt: 2 }}>
										<Title sx={{ pl: 1 }}>{t('AssignedTasks')}</Title>
										<BasicTable
											head={[t('Title'), t('Type'), t('State'), t('EndDate')]}
											filters={filters}
											page={page}
											pageCount={tasks?.pageCount}
											onPageChange={handlePageChange}>
											{tasks?.data?.map(item => (
												<TableRow key={item.id}>
													<TableCell>
														<Stack direction="row" alignItems="center">
															<PriorityIndicator priority={item.priority} />
															{item.name}
														</Stack>
													</TableCell>
													<TableCell>{item.type}</TableCell>
													<TableCell>
														<StatusIndicator state={item.state}>{t(item.state)}</StatusIndicator>
													</TableCell>
													<TableCell>{defaultDateTimeFormat(item.endDate)}</TableCell>
													{/* <TableCell align="right">
														<MenuDropdown>
															<MenuItem onClick={() => handleTaskEdit(item.id)}>{t('Edit')}</MenuItem>
															<MenuItem onClick={() => handleTaskDelete(item.id)}>{t('Delete')}</MenuItem>
														</MenuDropdown>
													</TableCell> */}
												</TableRow>
											))}
										</BasicTable>
									</StyledBox>
								</>
							),
						},
						// {
						// 	label: t('NeedForStationery'),
						// },
					]}
				/>
			</Grid>
			<AddClient
				title={t('NewClient')}
				open={openClient}
				onClose={() => setOpenClient(false)}
				onSuccess={() => setOpenClient(false)}
			/>
			<CreateTask
				title={t('NewTask')}
				open={openTask}
				onClose={() => setOpenTask(false)}
				onSuccess={() => setFetchTasks(uniqueId())}
			/>
		</Grid>
	);
};
