import api from 'api/axios';
import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { InputSearch, Spinner } from 'components';
import { Box, Stack } from '@mui/material';
import { CompanyIcon, UserIcon, CheckIcon } from 'assets/icons';
import { debounce } from 'lodash';
import qs from 'query-string';

const IconWrapper = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.gray002,
	width: 40,
	height: 40,
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const SubTitle = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
}));
const StyledBox = styled(Box)(({ theme, last }) => ({
	borderBottom: last !== 'true' && '1px solid #DDDDDD',
}));
const StyledRow = styled(Stack)(({ theme, selected }) => ({
	borderRadius: 8,
	cursor: 'pointer',
	backgroundColor: selected && theme.palette.base.background,
	'&:hover': {
		backgroundColor: theme.palette.base.background,
	},
}));
const StyledScrollBox = styled(Box)(({ theme, selected }) => ({
	maxHeight: '260px',
	overflowY: 'scroll',
	'::-webkit-scrollbar': {
		WebkitAppearance: 'none',
		width: '4px',
	},

	'::-webkit-scrollbar-thumb': {
		borderRadius: '4px',
		backgroundColor: 'rgba(0, 0, 0, .5)',
		boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
	},
}));

export const SearchDropdownSelect = ({
	initialValues = [],
	values = [],
	onChange,
	multiple = false,
	placeholder,
	url,
	optionLabel,
	optionSublabel = false,
	type,
}) => {
	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const [selected, setSelected] = useState(values);

	useEffect(() => {
		loadValues();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const setValues = (values, options) => {
		const result = options.filter(item => values.includes(item.id));
		setSelected(result);
	};

	const loadValues = async event => {
		setLoading(true);
		setOptions([]);

		try {
			const query = qs.stringify({ pageSize: 10, search: event?.target.value || '' });
			const res = await api.get(`${url}?${query}`);
			setOptions(res.data);
			if (initialValues?.length > 0 && res.data?.length > 0) {
				setValues(initialValues, res.data);
			}
		} finally {
			setLoading(false);
		}
	};

	const onSelect = option => {
		const exist = selected.find(item => item.id === option.id);

		if (exist) {
			const newValues = selected.filter(item => item.id !== option.id);
			setSelected(newValues);
			onChange(newValues);
		} else {
			const newValues = multiple ? [...selected, option] : [option];
			setSelected(newValues);
			onChange(newValues);
		}
	};

	const debouncedSearch = useMemo(() => debounce(loadValues, 500), []); // eslint-disable-line react-hooks/exhaustive-deps

	const filteredValues = options.filter(item => !selected.map(selected => selected.id).includes(item.id));

	const renderSelect = ({ item, idx, type, selected, last }) => (
		<StyledBox key={idx} sx={{ py: 1 }} last={last} onClick={() => onSelect(item)}>
			<StyledRow direction="row" alignItems="center" selected={selected}>
				<IconWrapper>
					{type === 'company' ? <CompanyIcon sx={{ fontSize: '21px' }} /> : <UserIcon sx={{ fontSize: '21px' }} />}
				</IconWrapper>
				<Stack sx={{ ml: 2, flex: 1 }}>
					<Title>{item[optionLabel]}</Title>
					{optionSublabel && <SubTitle>{item[optionSublabel]}</SubTitle>}
				</Stack>
				{selected && <CheckIcon sx={{ fontSize: '24px', mr: 2 }} />}
			</StyledRow>
		</StyledBox>
	);

	return (
		<>
			<InputSearch fullWidth placeholder={placeholder} onChange={debouncedSearch} sx={{ mt: 2, mb: 3 }} />
			<StyledScrollBox>
				{loading ? (
					<Spinner size={30} />
				) : (
					<>
						{selected.map((item, idx) => renderSelect({ item, idx, type, selected: true, last: 'true' }))}
						{filteredValues.map((item, idx) =>
							renderSelect({ item, idx, type, selected: false, last: filteredValues?.length === idx + 1 ? 'true' : '' }),
						)}
					</>
				)}
			</StyledScrollBox>
		</>
	);
};
