import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Input = ({ componentType, field, touched = {}, errors = {}, ...props }) => {
	const { t } = useTranslation();
	const error = touched[field.name] && errors[field.name] ? true : false;
	const helperText = error ? errors[field.name] : null;
	const Component = componentType || TextField;
	field.value = field.value || '';

	return (
		<Component error={error} helperText={t(helperText)} {...field} {...props}>
			{props.children}
		</Component>
	);
};

Input.propTypes = {
	componentType: PropTypes.node,
	field: PropTypes.object.isRequired,
	children: PropTypes.node,
	style: PropTypes.object,
};
