import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Link, useMediaQuery } from '@mui/material';
import { DOCUMENT_STATE } from 'constants/general';
import { EyeIcon } from 'assets/icons';

const Container = styled('div')(({ theme }) => ({
	borderRadius: 8,
	padding: '24px 16px',
	backgroundColor: theme.palette.base.white001,
}));
const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.base,
	fontWeight: theme.typography.fontWeight.bold,
}));
const TitleGray = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
}));
const StyledEyeIcon = styled(EyeIcon)(({ theme }) => ({
	fontSize: '24px',
	color: theme.palette.gray003,
}));
const StyledLink = styled(Link)(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.m,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.base.black002,
	textDecoration: 'unset',
	alignItems: 'center',
	display: 'flex',
}));
const Status = styled('p')(({ theme, status }) => {
	const color = {
		[DOCUMENT_STATE.NEW]: theme.palette.default.link,
		[DOCUMENT_STATE.REVIEWED]: theme.palette.default.warning,
		[DOCUMENT_STATE.PROCESSED]: theme.palette.default.success,
	};

	return {
		margin: 0,
		borderRadius: '20px',
		backgroundColor: '#F8F8F8',
		fontSize: theme.typography.fontSize.small,
		fontWeight: theme.typography.fontWeight.r,
		padding: '6px 14px 6px 16px',
		color: color[status],
	};
});

export const DocumentCard = ({ document = {} }) => {
	const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'));
	const { t } = useTranslation();

	return (
		<Container>
			{isTablet ? (
				<Grid container spacing={3} alignItems="center">
					<Grid item xs={10} display="flex">
						<StyledLink href={document.url} target="_blank" rel="noopener" sx={{ flex: 1 }}>
							<Title>{document.fileName}</Title>
						</StyledLink>
					</Grid>
					<Grid item xs={2}>
						<StyledLink href={document.url} target="_blank" rel="noopener">
							<StyledEyeIcon sx={{ ml: 1, fontSize: '24px' }} />
						</StyledLink>
					</Grid>
					<Grid item xs={12} display="flex" alignItems="center">
						<TitleGray sx={{ mr: 1 }}>{t('Status')}:</TitleGray>
						<Status status={document.state}>{t(document.state)}</Status>
					</Grid>
					<Grid item xs={12} display="flex" alignItems="center">
						<TitleGray sx={{ mr: 1 }}>{t('DocumentType')}:</TitleGray> {document.type || '-'}
					</Grid>
				</Grid>
			) : (
				<Grid container spacing={3} alignItems="center">
					<Grid item xs={12} md={7} display="flex">
						<StyledLink href={document.url} target="_blank" rel="noopener" sx={{ flex: 1 }}>
							<Title>{document.fileName}</Title>
						</StyledLink>
					</Grid>
					<Grid item xs={6} md={2} display="flex" alignItems="center">
						<Status status={document.state}>{t(document.state)}</Status>
					</Grid>
					<Grid item xs={4} md={2}>
						<TitleGray>{t('DocumentType')}</TitleGray>
						{document.type || '-'}
					</Grid>
					<Grid item xs={2} md={1}>
						<StyledLink href={document.url} target="_blank" rel="noopener">
							<StyledEyeIcon sx={{ ml: 1, fontSize: '24px' }} />
						</StyledLink>
					</Grid>
				</Grid>
			)}
		</Container>
	);
};

DocumentCard.propTypes = {
	document: PropTypes.object.isRequired,
};
