import React from 'react';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as BasicSelect } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { ExpandMoreIcon } from 'assets/icons';

const Label = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.black002,
	fontWeight: theme.typography.fontWeight.bold,
	fontSize: theme.typography.fontSize.small,
}));
const Asterisk = styled('span')(({ theme }) => ({
	color: theme.palette.default.error,
	padding: '0 2px',
}));
const StyledSelect = styled(BasicSelect)(({ theme }) => ({
	overflow: 'hidden',
}));
const BootstrapInput = styled(InputBase)(({ theme, placeholder, show }) => ({
	'&:before': show === 'true' && {
		top: 0,
		cursor: 'pointer',
		content: `"${placeholder}"`,
		position: 'absolute',
		whiteSpace: 'nowrap',
		fontSize: theme.typography.fontSize.small,
		color: theme.palette.gray003,
		padding: '14px 14px 14px 16px',
		zIndex: 1,
		pointerEvents: 'none',
	},
	label: {
		fontSize: '20px',
	},
	'& .MuiInputBase-input': {
		borderRadius: 8,
		position: 'relative',
		fontSize: theme.typography.fontSize.small,
		fontWeight: theme.typography.fontWeight.r,
		backgroundColor: 'transparent',
		border: `1px solid ${theme.palette.gray003}`,
		padding: '13px 14px 13px 16px',
		'&:focus': {
			borderRadius: 8,
		},
	},
}));

export const Select = ({
	options,
	field,
	label,
	placeholder,
	optionLabel = 'label',
	optionValue = 'value',
	InputLabelProps = {},
	style,
	...props
}) => {
	const { t } = useTranslation();
	const { required } = InputLabelProps;

	return (
		<Box sx={props.sx}>
			<FormControl fullWidth={props.fullWidth}>
				{label && (
					<Label>
						{label}
						{required && <Asterisk>*</Asterisk>}
					</Label>
				)}
				<StyledSelect
					{...field}
					input={<BootstrapInput />}
					show={!field.value ? 'true' : ''}
					placeholder={placeholder}
					disabled={props.disabled}
					IconComponent={ExpandMoreIcon}
					sx={{ ...style }}>
					{options.length === 0 && (
						<MenuItem value="" disabled>
							{t('NoOptions')}
						</MenuItem>
					)}
					{options?.map((item, idx) => (
						<MenuItem key={idx} value={item[optionValue]}>
							{t(item[optionLabel])}
						</MenuItem>
					))}
				</StyledSelect>
			</FormControl>
		</Box>
	);
};

Select.propTypes = {
	options: PropTypes.array.isRequired,
	field: PropTypes.object.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	optionLabel: PropTypes.string,
	InputLabelProps: PropTypes.object,
	style: PropTypes.object,
};
