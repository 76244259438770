import React from 'react';
import { SvgIcon } from '@mui/material';

function EmailIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 18 18" fill="none">
			<g>
				<path
					d="M16.165 2.9999C16.0968 2.99287 16.0281 2.99287 15.96 2.9999H1.95995C1.87022 3.00128 1.78108 3.01473 1.69495 3.0399L8.91995 10.2349L16.165 2.9999Z"
					fill="currentColor"
				/>
				<path
					d="M16.905 3.69507L9.62496 10.9451C9.4376 11.1313 9.18415 11.2359 8.91996 11.2359C8.65578 11.2359 8.40233 11.1313 8.21496 10.9451L0.999966 3.75007C0.977786 3.83159 0.966025 3.91559 0.964966 4.00007V14.0001C0.964966 14.2653 1.07032 14.5196 1.25786 14.7072C1.4454 14.8947 1.69975 15.0001 1.96497 15.0001H15.965C16.2302 15.0001 16.4845 14.8947 16.6721 14.7072C16.8596 14.5196 16.965 14.2653 16.965 14.0001V4.00007C16.961 3.8959 16.9407 3.79299 16.905 3.69507ZM2.64997 14.0001H1.95497V13.2851L5.58996 9.68007L6.29496 10.3851L2.64997 14.0001ZM15.955 14.0001H15.255L11.61 10.3851L12.315 9.68007L15.95 13.2851L15.955 14.0001Z"
					fill="currentColor"
				/>
			</g>
		</SvgIcon>
	);
}

export default EmailIcon;
