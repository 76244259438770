import React, { useState } from 'react';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && children}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
	'.MuiTab-root': {
		textTransform: 'none',
		fontWeight: theme.typography.fontWeight.r,
		color: theme.palette.gray003,
	},
	'.Mui-selected': {
		color: `${theme.palette.gold002} !important`,
		fontSize: theme.typography.fontSize.small,
		fontWeight: theme.typography.fontWeight.bold,
	},
	'& .MuiTabs-indicator': {
		backgroundColor: theme.palette.gold002,
	},
}));

export const PageTabs = ({ tabs = [] }) => {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
					{tabs.map((item, index) => (
						<Tab key={index} label={item.label} {...a11yProps(index)} />
					))}
				</StyledTabs>
			</Box>
			{tabs.map((item, index) => (
				<TabPanel key={index} value={value} index={index}>
					{item.content}
				</TabPanel>
			))}
		</Box>
	);
};
