import api from 'api/axios';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDetails, showToast, defaultDateFormat } from 'utils/utils';
import { MiniTable, InputUpload } from 'components';
import { Grid, TableCell, TableRow } from '@mui/material';
import { DOCUMENT_STATE } from 'constants/general';
import { uniqueId } from 'lodash';

const Indicator = styled('p')(({ theme, state }) => {
	const color = {
		[DOCUMENT_STATE.NEW]: theme.palette.default.link,
		[DOCUMENT_STATE.REVIEWED]: theme.palette.default.warning,
		[DOCUMENT_STATE.PROCESSED]: theme.palette.default.success,
	};

	return {
		margin: 0,
		textTransform: 'lovercase',
		color: color[state],
	};
});

export const ClientHome = () => {
	const { t } = useTranslation();
	// const [fetch, setFetch] = useState('');

	const { details: documents } = useDetails('/client/documents', []);

	// const payments = [
	// 	{ title: 'Pelno mokestis', amount: '125 000 Eur', endDate: '2023-01-15' },
	// 	{ title: 'PVM', amount: '23 000 Eur', endDate: '2023-01-10' },
	// 	{ title: 'GPM', amount: '10 000 Eur', endDate: '2023-01-05' },
	// 	{ title: 'Taršos mokestis', amount: '3000 Eur', endDate: '2023-01-20' },
	// 	{ title: 'Mokestis', amount: '12 000 Eur', endDate: '2023-01-01' },
	// ];

	// const handleUpload = async files => {
	// 	try {
	// 		await api.post('/client/documents', { document: files }, { headers: { 'Content-Type': 'multipart/form-data' } });
	// 		setFetch(uniqueId());

	// 		showToast({
	// 			title: t('UploadFileSuccess'),
	// 			type: 'success',
	// 		});
	// 	} catch (error) {
	// 		showToast({
	// 			title: t('SomethingWrongTryAgain),
	// 			type: 'error',
	// 		});
	// 	}
	// };

	return (
		<>
			<Grid container spacing={2} sx={{ mt: 3 }}>
				{/* <Grid item xs={12} sx={{ my: 2 }}>
					<InputUpload
						onChange={handleUpload}
						multiple
						hideList
						text={t('UploadDocumentDocuments')}
						sx={{ mt: 2, '.drop-area': { height: '80px', border: '2px dashed #DDDDDD', background: 'white' } }}
					/>
				</Grid> */}
				<Grid item xs={12} md={6}>
					<MiniTable
						title={t('Documents')}
						link={{ label: t('AllDocuments'), url: '/documents' }}
						head={[t('Title'), t('Uploaded'), t('State')]}>
						{documents?.data?.map((item, idx) => (
							<TableRow key={idx}>
								<TableCell>{item.fileName}</TableCell>
								<TableCell>{defaultDateFormat(item.createdAt)}</TableCell>
								<TableCell>
									<Indicator state={item.state}>{t(item.state)}</Indicator>
								</TableCell>
							</TableRow>
						))}
					</MiniTable>
				</Grid>
				{/* <Grid item xs={12} md={6}>
					<MiniTable
						title={t('PaymentCalendar')}
						link={{ label: t('AllPayments'), url: '/payments' }}
						head={[t('Payment'), t('Amount'), t('PayDay')]}>
						{payments.map((row, idx) => (
							<TableRow key={idx}>
								<TableCell>{row.title}</TableCell>
								<TableCell>{row.amount}</TableCell>
								<TableCell>{defaultDateFormat(row.endDate)}</TableCell>
							</TableRow>
						))}
					</MiniTable>
				</Grid> */}
			</Grid>
		</>
	);
};
