import api from 'api/axios';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { schema } from './schema';
import { removeSpaces, showToast } from 'utils/utils';
import { Formik, Form, Field } from 'formik';
import { Input } from 'components';
import { Modal, Button, Select, SearchDropdownSelect } from 'components';
import { Box, Stack, Grid, useTheme } from '@mui/material';
import { PhoneIcon, EmailIcon } from 'assets/icons';
import { EMPLOYEE_POSITION } from 'constants/general';
import { useMediaQuery } from '@mui/material';

const HeadingText = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
	borderBottom: '1px solid #DDDDDD',
}));

const initialValues = {
	firstName: '',
	lastName: '',
	position: '',
	phone: '+370',
	email: '',
};

export const AddEmployee = ({ employee = { ...initialValues }, title, buttonTitle, open, onClose, onSuccess }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const { t } = useTranslation();
	const theme = useTheme();

	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		const clientIds = employee.clientIds?.map(item => ({ id: item }));
		setCompanies(clientIds);
	}, [employee.clientIds, open]);

	const addNewEmployee = async values => {
		const clientIds = companies.map(item => item.id);

		try {
			await api.post('/users/create', { ...values, clientIds });
			onSuccess();
			onClose();

			showToast({
				title: t('InviteEmployeeSuccess'),
				type: 'success',
			});
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const updateEmployee = async (id, values) => {
		const clientIds = companies.map(item => item.id);

		const updateBody = {
			firstName: values.firstName,
			lastName: values.lastName,
			position: values.position,
			phone: values.phone,
			email: values.email,
			clientIds,
		};

		try {
			await api.put(`/employees/${id}`, updateBody);
			onSuccess();
			onClose();

			showToast({
				title: t('UpdateEmployeeSuccess'),
				type: 'success',
			});
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const handleSubmit = async values => {
		if (employee.id) {
			updateEmployee(employee.id, values);
		} else {
			addNewEmployee(values);
		}
	};

	return (
		<Modal title={title} open={open} onClose={onClose} width="60%">
			<Formik initialValues={employee} validationSchema={Yup.object(schema)} onSubmit={handleSubmit}>
				{({ values, setFieldValue, touched, errors, setErrors }) => (
					<Form>
						<Grid container>
							<Grid item xs={12} md={6} sx={{ pr: 2 }}>
								<Field
									id="firstName"
									name="firstName"
									component={Input}
									label={t('FirstName')}
									placeholder={t('FirstName')}
									errors={errors}
									touched={touched}
									value={values.firstName}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
										required: true,
									}}
									sx={{ mt: 4 }}
								/>
								<Field
									id="lastName"
									name="lastName"
									component={Input}
									label={t('LastName')}
									placeholder={t('LastName')}
									errors={errors}
									touched={touched}
									value={values.lastName}
									fullWidth
									type="text"
									variant="standard"
									InputLabelProps={{
										shrink: true,
										required: true,
									}}
									sx={{ mt: 2 }}
								/>
								<Field
									id="position"
									name="position"
									component={Select}
									label={t('Position')}
									placeholder={t('Position')}
									value={values.position}
									fullWidth
									options={[EMPLOYEE_POSITION.accountant]}
									sx={{ mt: 2 }}
								/>
								<Stack direction="row">
									<PhoneIcon sx={{ color: theme.palette.gray003, fontSize: '18px', mt: '53px', mr: 1 }} />
									<Field
										id="phone"
										name="phone"
										component={Input}
										label={t('PhoneNumber')}
										placeholder={t('+370')}
										errors={errors}
										touched={touched}
										value={values.phone}
										fullWidth
										type="text"
										variant="standard"
										InputLabelProps={{
											shrink: true,
											required: true,
										}}
										sx={{ mt: 2 }}
									/>
								</Stack>
								<Stack direction="row">
									<EmailIcon sx={{ color: theme.palette.gray003, fontSize: '18px', mt: '53px', mr: 1 }} />
									<Field
										id="email"
										name="email"
										component={Input}
										label={t('ContactEmail')}
										placeholder={t('Email')}
										errors={errors}
										touched={touched}
										value={values.email}
										onChange={e => setFieldValue('email', removeSpaces(e.target.value))}
										fullWidth
										type="text"
										variant="standard"
										InputLabelProps={{
											shrink: true,
											required: true,
										}}
										sx={{ mt: 2 }}
									/>
								</Stack>
							</Grid>
							<Grid item xs={12} md={6} sx={{ pl: isMobile ? 0 : 2 }}>
								<HeadingText sx={{ mt: 4 }}>{t('AssociateWith')}</HeadingText>
								<SearchDropdownSelect
									onChange={option => setCompanies(option)}
									values={companies}
									initialValues={employee?.clientIds}
									placeholder={t('SearchCompany')}
									optionLabel="companyName"
									optionSublabel="position"
									url="/clients/list/light"
									type="company"
									multiple
								/>
							</Grid>
							<Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
								<Button
									type="submit"
									sx={{ mt: 5 }}
									disabled={
										!values.firstName ||
										!values.lastName ||
										!values.phone ||
										!values.email ||
										!!errors.firstName ||
										!!errors.lastName ||
										!!errors.phone ||
										!!errors.email
									}>
									{buttonTitle || t('Save')}
								</Button>
							</Box>
						</Grid>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};
