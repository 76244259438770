import api from 'api/axios';
import * as Yup from 'yup';
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { removeSpaces, showToast, removeJWT } from 'utils/utils';
import { Box, useTheme } from '@mui/material';
import { LoginLayout } from './components/LoginLayout';
import { Checkbox, Button } from 'components';
import { authSchema } from './authSchema';
import { EmailField } from './components/EmailField';
import { PasswordField } from './components/PasswordField';
const CheckboxLabel = styled('p')(({ theme }) => ({
	margin: 0,
	whiteSpace: 'nowrap',
	color: theme.palette.base.black002,
	fontWeight: theme.typography.fontWeight.bold,
}));
const StyledLink = styled(Link)(({ theme }) => ({
	whiteSpace: 'nowrap',
	color: theme.palette.default.link,
	fontWeight: theme.typography.fontWeight.bold,
	textDecoration: 'none',
}));

const initialValues = {
	email: '',
	password: '',
	rememberMe: false,
};

export const Login = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const { t } = useTranslation();

	const login = async values => {
		try {
			removeJWT();

			const res = await api.post('/login', {
				email: values.email,
				password: values.password,
			});

			if (values.rememberMe === true) {
				localStorage.setItem('jwt', res.data.token);
			} else {
				sessionStorage.setItem('jwt', res.data.token);
			}

			navigate('/', { replace: true, state: { email: values.email } });
		} catch (error) {
			const message = error.response?.data?.message;

			if (message === 'UserNotFound') {
				showToast({
					title: t('UserNotFound'),
					type: 'error',
				});
			} else {
				showToast({
					title: t('LoginError'),
					type: 'error',
				});
			}
		}
	};

	const handleSubmit = async values => {
		login(values);
	};

	const onKeyDown = (e, values, errors) => {
		if (e.key === 'Enter' && values.email && values.password && !errors.email && !errors.password) {
			login(values);
		}
	};

	const schema = {
		email: authSchema.email,
		password: authSchema.password,
	};

	return (
		<LoginLayout type={'login'}>
			<Formik initialValues={initialValues} validationSchema={Yup.object(schema)} onSubmit={handleSubmit}>
				{({ values, setFieldValue, touched, errors, setErrors }) => (
					<Form>
						<EmailField
							id="email"
							name="email"
							label={t('Email')}
							errors={errors}
							touched={touched}
							value={values.email}
							onChange={e => setFieldValue('email', removeSpaces(e.target.value))}
							onKeyDown={e => onKeyDown(e, values, errors)}
						/>
						<PasswordField
							id="password"
							name="password"
							label={t('Password')}
							errors={errors}
							touched={touched}
							value={values.password}
							onKeyDown={e => onKeyDown(e, values, errors)}
							sx={{ mt: 2 }}
						/>
						<Box
							spacing={1}
							display="flex"
							flexWrap={'wrap'}
							sx={{
								pt: 1,
								justifyContent: 'space-between',
								alignItems: 'center',
							}}>
							<Field
								id="rememberMe"
								name="rememberMe"
								component={Checkbox}
								value={values.rememberMe}
								color={theme.palette.gray003}
								label={<CheckboxLabel>{t('RememberMe')}</CheckboxLabel>}
							/>
							<StyledLink to="/password-forgot">{t('ForgotPassword')}?</StyledLink>
						</Box>
						<Button
							type="submit"
							fullwidth="true"
							sx={{ mt: 5, mb: 4 }}
							disabled={!values.email || !values.password || !!errors.email || !!errors.password}>
							{t('LogIn')}
						</Button>
					</Form>
				)}
			</Formik>
		</LoginLayout>
	);
};
