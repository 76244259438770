import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, InputAdornment, useTheme } from '@mui/material';
import { SearchIcon } from 'assets/icons';

const StyledSearch = styled(TextField)(({ theme }) => ({
	'.MuiInputBase-root': {
		backgroundColor: theme.palette.base.background,
		borderRadius: '30px',
		color: theme.palette.black002,
		fontSize: theme.typography.fontSize.small,
		input: {
			padding: '10px 16px 10px 0px',
		},
		'input:-webkit-autofill, input:-webkit-autofill:focus': {
			transition: 'background-color 600000s 0s, color 600000s 0s',
		},
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			border: 'none',
		},
	},
}));

export const InputSearch = ({ iconSize, ...props }) => {
	const theme = useTheme();

	return (
		<StyledSearch
			{...props}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon sx={{ color: theme.palette.gray003, fontSize: iconSize || '21px' }} />
					</InputAdornment>
				),
			}}
		/>
	);
};
