import React from 'react';
import PropTypes from 'prop-types';
import logo from './logo.png';

function LogoIcon(props) {
	return <img src={logo} width={props.width} height={props.height} alt="Logo" />;
}

LogoIcon.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string,
};

export default LogoIcon;
