import React from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDetails } from 'utils/utils';
import { Comments } from 'components';
import { DocumentCard } from './components/DocumentCard';
import { Grid } from '@mui/material';

const Breadcrumb = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.xs,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
	strong: {
		color: theme.palette.base.black002,
	},
}));

export const AccountantDocument = () => {
	const { t } = useTranslation();
	const { id } = useParams();

	const { details: document } = useDetails(`/documents/profile/${id}`, {});

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Breadcrumb sx={{ mt: 2 }}>
					{t('Documents')} / <strong>{document?.fileName}</strong>
				</Breadcrumb>
			</Grid>
			<Grid item xs={12}>
				<DocumentCard document={document} />
			</Grid>
			<Grid item xs={12}>
				<Comments userRole="employee" groupId={id} type="document" />
			</Grid>
		</Grid>
	);
};
