import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDetails, defaultDateTimeFormat } from 'utils/utils';
import { BasicTable, PageTabs, AddClient } from 'components';
import { CompanyCard } from './components/CompanyCard';
import { Grid, Stack, Box, TableCell, TableRow } from '@mui/material';
import { TASK_PRIORITY, STATE, STATE_OPTIONS, DEFAULT_PAGE_SIZE } from 'constants/general';
import qs from 'query-string';
import { debounce, uniqueId } from 'lodash';

const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const Breadcrumb = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.xs,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
	strong: {
		color: theme.palette.base.black002,
	},
}));
const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.base.white001,
	borderRadius: 8,
}));
const PriorityIndicator = styled('div')(({ theme, priority }) => {
	const color = {
		[TASK_PRIORITY.HIGH]: theme.palette.default.error,
		[TASK_PRIORITY.MEDIUM]: theme.palette.default.warning,
		[TASK_PRIORITY.LOW]: theme.palette.default.success,
	};

	return {
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		background: color[priority],
		marginRight: '4px',
	};
});
const StatusIndicator = styled('p')(({ theme, state }) => {
	const color = {
		[STATE.NEW]: theme.palette.default.link,
		[STATE.ONGOING]: theme.palette.default.warning,
		[STATE.FINISHED]: theme.palette.default.success,
	};

	return {
		margin: 0,
		// textTransform: 'capitalize',
		color: color[state],
	};
});

export const Client = () => {
	const { t } = useTranslation();
	const { id } = useParams();

	const [page, setPage] = useState(1);
	const [name, setName] = useState('');
	const [type, setType] = useState('');
	const [state, setState] = useState('');
	const [fetch, setFetch] = useState('');
	const [openClient, setOpenClient] = useState(false);

	const query = qs.stringify({ page, pageSize: DEFAULT_PAGE_SIZE, name, type, state });
	const { details: tasks } = useDetails(`/clients/tasks/${id}?${query}`, [], [page, name, type, state]);
	const { details: client } = useDetails(`/clients/profile/${id}`, {}, [fetch]);
	const { details: employees } = useDetails(`/clients/employees/${id}`, []);

	const handlePageChange = (e, page) => {
		setPage(page);
	};

	const taskSearch = useMemo(() => debounce(e => setName(e.target.value), 500), []);
	const taskTypeSelect = e => setType(e.target.value);

	const filters = [
		{ type: 'search', name: 'name', onChange: taskSearch },
		{
			type: 'select',
			options: [{ label: 'Type', value: 'type' }],
			field: { value: type, onChange: taskTypeSelect },
		},
		{
			type: 'select',
			options: STATE_OPTIONS,
			field: { value: state, onChange: e => setState(e.target.value) },
		},
		{ type: 'none' },
	];

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Breadcrumb sx={{ mt: 2 }}>
					{t('Clients')} / {client.companyName} / <strong>{t('Main')}</strong>
				</Breadcrumb>
			</Grid>
			<Grid item xs={12} sm={3}>
				<CompanyCard company={client} assignedEmployees={employees} onEdit={() => setOpenClient(true)} />
			</Grid>
			<Grid item xs={12} sm={9}>
				<PageTabs
					tabs={[
						{
							label: t('Main'),
							content: (
								<StyledBox sx={{ pt: 2, mt: 2 }}>
									<Title sx={{ pl: 1 }}>{t('RelatedTasks')}</Title>
									<BasicTable
										head={[t('Title'), t('Type'), t('State'), t('EndDate')]}
										filters={filters}
										page={page}
										pageCount={tasks?.pageCount}
										onPageChange={handlePageChange}>
										{tasks?.data?.map(item => (
											<TableRow key={item.id}>
												<TableCell>
													<Stack direction="row" alignItems="center">
														<PriorityIndicator priority={item.priority} />
														{item.name}
													</Stack>
												</TableCell>
												<TableCell>{item.type}</TableCell>
												<TableCell>
													<StatusIndicator state={item.state}>{t(item.state)}</StatusIndicator>
												</TableCell>
												<TableCell>{defaultDateTimeFormat(item.endDate)}</TableCell>
											</TableRow>
										))}
									</BasicTable>
								</StyledBox>
							),
						},
						// {
						// 	label: t('Authorizations'),
						// },
						{
							label: t('Employees'),
							content: (
								<>
									<BasicTable sx={{ mt: 2 }} head={[t('NameSurname'), t('Position')]}>
										{employees?.map(item => (
											<TableRow key={item.id} sx={{ cursor: 'pointer' }}>
												<TableCell>{item.name}</TableCell>
												<TableCell>{t(item.position)}</TableCell>
											</TableRow>
										))}
									</BasicTable>
								</>
							),
						},
					]}
				/>
			</Grid>

			<AddClient
				client={client}
				open={openClient}
				title={t('EditClient')}
				onClose={() => setOpenClient(false)}
				onSuccess={() => setFetch(uniqueId())}
			/>
		</Grid>
	);
};
