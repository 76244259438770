import api from 'api/axios';
import React, { useState, useMemo } from 'react';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDetails, showToast } from 'utils/utils';
import { Modal, Button, AddEmployee, BasicTable, MenuDropdown } from 'components';
import { Box, TableCell, TableRow, MenuItem } from '@mui/material';
import { EMPLOYEE_POSITION, DEFAULT_PAGE_SIZE } from 'constants/general';
import { AddIcon } from 'assets/icons';
import qs from 'query-string';
import { debounce, uniqueId } from 'lodash';

const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.h2,
	fontWeight: theme.typography.fontWeight.bold,
}));

export const Employees = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [openEmployee, setOpenEmployee] = useState(false);
	const [fetchEmployees, setFetchEmployees] = useState('');

	const [position, setPosition] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [page, setPage] = useState(1);
	const [itemDelete, setItemDelete] = useState('');

	const query = qs.stringify({ page, pageSize: DEFAULT_PAGE_SIZE, position, name, email });
	const { details: employees } = useDetails(`/employees/list?${query}`, [], [page, position, name, email, fetchEmployees]);

	const handlePageChange = (e, page) => {
		setPage(page);
	};
	const handleClick = item => {
		navigate('/employees/' + item.id);
	};
	const handleDelete = async id => {
		try {
			await api.delete(`/employees/${id}`);
			setFetchEmployees(uniqueId());
			setItemDelete('');

			showToast({
				title: t('DeleteEmployeeSuccess'),
				type: 'success',
			});
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const nameSearch = useMemo(() => debounce(e => setName(e.target.value), 500), []);
	const emailSearch = useMemo(() => debounce(e => setEmail(e.target.value), 500), []);

	const filters = [
		{
			type: 'select',
			options: [{ label: 'All', value: '' }, EMPLOYEE_POSITION.accountant],
			field: { value: position, onChange: e => setPosition(e.target.value) },
		},
		{ type: 'search', name: 'name', onChange: nameSearch },
		{ type: 'none' },
		{ type: 'search', name: 'email', onChange: emailSearch },
		{ type: 'none' },
	];

	return (
		<>
			<Title sx={{ mt: 3, ml: 1 }}>{t('Employees')}</Title>
			<Button onClick={() => setOpenEmployee(true)} sx={{ mt: 1 }}>
				<AddIcon sx={{ fontSize: 21, mr: 1 }} />
				{t('AddEmployee')}
			</Button>

			<BasicTable
				sx={{ mt: 5 }}
				head={[t('EmployeeType'), t('NameSurname'), t('PhoneNumber'), t('Email'), t('Actions')]}
				filters={filters}
				page={page}
				pageCount={employees?.pageCount}
				onPageChange={handlePageChange}>
				{employees?.data?.map(item => (
					<TableRow key={item.id}>
						<TableCell onClick={() => handleClick(item)} sx={{ textTransform: 'capitalize' }}>
							{t(item.position)}
						</TableCell>
						<TableCell onClick={() => handleClick(item)}>{item.name}</TableCell>
						<TableCell onClick={() => handleClick(item)}>{item.phone}</TableCell>
						<TableCell onClick={() => handleClick(item)}>{item.email}</TableCell>
						<TableCell align="right">
							<MenuDropdown>
								<MenuItem onClick={() => handleClick(item)}>{t('Preview')}</MenuItem>
								<MenuItem onClick={() => setItemDelete(item.id)}>{t('Delete')}</MenuItem>
							</MenuDropdown>
						</TableCell>
					</TableRow>
				))}
			</BasicTable>

			<AddEmployee
				title={t('NewEmployee')}
				open={openEmployee}
				onClose={() => setOpenEmployee(false)}
				onSuccess={() => setFetchEmployees(uniqueId())}
			/>

			<Modal title={t('AreYouSureToDelete') + '?'} open={!!itemDelete} onClose={() => setItemDelete('')} width="40%">
				<Box sx={{ mt: 3, textAlign: 'right' }}>
					<Button variant="text" sx={{ color: 'gray' }} onClick={() => setItemDelete('')}>
						{t('Cancel')}
					</Button>
					<Button variant="outlined" onClick={() => handleDelete(itemDelete)}>
						{t('Delete')}
					</Button>
				</Box>
			</Modal>
		</>
	);
};
