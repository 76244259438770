import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as PaginationComponent } from '@mui/material';
import { styled } from '@mui/system';
import Stack from '@mui/material/Stack';

const StyledPagination = styled(PaginationComponent)(({ theme }) => ({
	alignItem: 'flex-end',
	'& > *': {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	'& ul li:first-of-type, ul li:last-of-type': {
		display: 'none',
	},
	'& .MuiButtonBase-root': {
		color: theme.palette.gray003,
		fontSize: theme.typography.fontSize.small,
		fontWeight: theme.typography.fontWeight.bold,
		'&:hover': {
			backgroundColor: theme.palette.base.white,
		},
	},
	'& .Mui-selected': {
		color: theme.palette.base.black002,
	},
	'& .MuiPaginationItem-root': {
		minWidth: '10px',
		padding: '0 1px',
	},
	'& .MuiPaginationItem-root.Mui-selected': {
		backgroundColor: theme.palette.base.white,
	},
}));

export const Pagination = props => <Stack sx={{ width: '100%', mt: 2 }}>{!!props.count && <StyledPagination {...props} />}</Stack>;

Pagination.propTypes = {
	count: PropTypes.number,
};
