import React from 'react';
import { styled } from '@mui/system';
import { Field } from 'formik';
import { Input } from 'components';

const StyledPasswordField = styled(Field)(({ theme }) => ({
	input: {
		'::placeholder': {
			fontWeight: theme.typography.fontWeight.bold,
		},
	},
}));

export const PasswordField = ({ ...props }) => {
	return (
		<StyledPasswordField
			type="password"
			component={Input}
			placeholder={props.label}
			InputLabelProps={{
				shrink: true,
				// required: true,
			}}
			fullWidth
			variant="standard"
			{...props}
		/>
	);
};
