import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { AuthProvider } from 'context/AuthProvider';
import { UserProvider } from 'context/UserProvider';
import { App, AppSpinner } from 'components';
import './i18n';
import TimeAgo from 'javascript-time-ago';
import theme from 'assets/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/toastify.css';

import en from 'javascript-time-ago/locale/en.json';
import lt from 'javascript-time-ago/locale/lt.json';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(lt);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ThemeProvider theme={theme}>
		<Suspense fallback={<AppSpinner />}>
			<CssBaseline />
			<BrowserRouter>
				<AuthProvider>
					<UserProvider>
						<Routes>
							<Route path="/*" element={<App />} />
						</Routes>
					</UserProvider>
				</AuthProvider>
				<ToastContainer
					position="top-center"
					autoClose={false}
					closeButton={false}
					closeOnClick={false}
				/>
			</BrowserRouter>
		</Suspense>
	</ThemeProvider>
);
