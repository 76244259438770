import api from 'api/axios';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDetails, showToast } from 'utils/utils';
import { InputUpload, Comments, Button, CreateTask } from 'components';
import { ServiceCard } from './components/ServiceCard';
import { Grid, Box, Stack, useMediaQuery } from '@mui/material';
import { uniqueId } from 'lodash';
import { CloseIcon } from 'assets/icons';

const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const Container = styled('div')(({ theme }) => ({
	borderRadius: 8,
	padding: '16px 16px',
	backgroundColor: theme.palette.base.white001,
}));
const Breadcrumb = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.xs,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
	strong: {
		color: theme.palette.base.black002,
	},
}));
const FilesList = styled(Box)(({ theme, active }) => ({
	display: 'flex',
	flexWrap: 'wrap',
}));
const FileTitle = styled('p')(({ theme, active }) => ({
	margin: 0,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));
const FileChip = styled('div')(({ theme }) => ({
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.r,
	backgroundColor: '#DDDDDD',
	border: 0,
	borderRadius: 8,
	padding: '10px 16px',
	maxWidth: '200px',
	display: 'flex',
	alignItems: 'center',
	// cursor: 'pointer',
}));

export const Service = () => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const { t } = useTranslation();
	const { id } = useParams();
	const [fetch, setFetch] = useState('');
	const [openTask, setOpenTask] = useState(false);

	const { details: service } = useDetails(`/orders/${id}`, [], [fetch]);
	const { details: documents } = useDetails(`/admin/documents/order/${id}`, [], [fetch]);

	const handleDocumentUpload = async files => {
		try {
			await api.post(`/admin/documents/order/${id}`, { document: files }, { headers: { 'Content-Type': 'multipart/form-data' } });
			setFetch(uniqueId());
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const handleCommentSubmit = async comment => {
		try {
			await api.post(`/comments/${id}`, { type: 'order', text: comment.value });
			setFetch(uniqueId());
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	const handleStateUpdate = async state => {
		try {
			await api.put(`/orders/${id}`, { state });
			setFetch(uniqueId());
		} catch (error) {
			showToast({
				title: t('SomethingWrongTryAgain'),
				type: 'error',
			});
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between" spacing={2} sx={{ mt: 3 }}>
					<Breadcrumb>
						{t('Services')} / {service.title}
					</Breadcrumb>
					<Button onClick={() => setOpenTask(true)}>{t('CreateTask')}</Button>
				</Stack>
			</Grid>
			<Grid item xs={12}>
				{service?.state && <ServiceCard service={service} onStateEdit={handleStateUpdate} />}
			</Grid>
			<Grid item xs={12}>
				<Comments userRole="admin" groupId={id} type="order" onSubmit={handleCommentSubmit} />
			</Grid>
			<Grid item xs={12}>
				<Container>
					<Title sx={{ pb: 2, borderBottom: '1px solid #DDD' }}>{t('Documents')}</Title>
					<InputUpload
						onChange={handleDocumentUpload}
						multiple
						hideList
						text={t('UploadDocumentDocuments')}
						sx={{ mt: 2, '.drop-area': { height: '80px', border: '2px dashed #DDDDDD' } }}
					/>
					<FilesList sx={{ mt: 1 }}>
						{documents.map((file, index) => (
							<FileChip key={index} sx={{ mr: 1, mt: 1 }}>
								<FileTitle>{file.fileName}</FileTitle>
								{/* <CloseIcon sx={{ ml: 1, fontSize: '10px' }} /> */}
							</FileChip>
						))}
					</FilesList>
				</Container>
			</Grid>

			<CreateTask title={t('NewTask')} open={openTask} onClose={() => setOpenTask(false)} />
		</Grid>
	);
};
