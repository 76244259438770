import React from 'react';
import { DashboardIcon, UsersIcon, CompanyIcon, BookIcon, FeatherIcon, DocumentIcon, DollarIcon, ChartIcon, RackIcon } from 'assets/icons';

export const adminMenu = [
	{ url: '/', label: 'Dashboard', icon: <DashboardIcon sx={{ fontSize: 24 }} />, permissions: [] },
	{
		url: '/employees',
		label: 'Employees',
		icon: <UsersIcon sx={{ fontSize: 24 }} />,
		permissions: [],
	},
	{
		url: '/clients',
		label: 'Clients',
		icon: <CompanyIcon sx={{ fontSize: 24 }} />,
		permissions: [],
	},
	{ url: '/tasks', label: 'Tasks', icon: <BookIcon sx={{ fontSize: 24 }} />, permissions: [] },
	{ url: '/services', label: 'Services', icon: <FeatherIcon sx={{ fontSize: 24 }} />, permissions: [] },
	{ url: '/documents', label: 'Documents', icon: <DocumentIcon sx={{ fontSize: 24 }} />, permissions: [] },
];

export const clientMenu = [
	{ url: '/', label: 'Dashboard', icon: <DashboardIcon sx={{ fontSize: 24 }} />, permissions: [] },
	{ url: '/documents', label: 'Documents', icon: <DocumentIcon sx={{ fontSize: 24 }} />, permissions: [] },
	// { url: '/payments', label: 'Payments', icon: <DollarIcon sx={{ fontSize: 24 }} />, permissions: [] },
	// { url: '/news', label: 'News', icon: <BookIcon sx={{ fontSize: 24 }} />, permissions: [] },
	// {
	// 	url: '/administration',
	// 	label: 'Administration',
	// 	icon: <UsersIcon sx={{ fontSize: 24 }} />,
	// 	permissions: [],
	// },
	// { url: '/reports', label: 'Reports', icon: <ChartIcon sx={{ fontSize: 24 }} />, permissions: [] },
	{ url: '/services', label: 'Services', icon: <RackIcon sx={{ fontSize: 24 }} />, permissions: [] },
];

export const accountantMenu = [
	{ url: '/', label: 'Dashboard', icon: <DashboardIcon sx={{ fontSize: 24 }} />, permissions: [] },
	{
		url: '/clients',
		label: 'Clients',
		icon: <UsersIcon sx={{ fontSize: 24 }} />,
		permissions: [],
	},
	{ url: '/tasks', label: 'Tasks', icon: <BookIcon sx={{ fontSize: 24 }} />, permissions: [] },
	{ url: '/documents', label: 'Documents', icon: <DocumentIcon sx={{ fontSize: 24 }} />, permissions: [] },
];
