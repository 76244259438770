import useAuth from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import i18next from 'i18next';
import { Layout, RequireLogin } from 'components';
import {
	Login,
	// Signup,
	PasswordForgot,
	PasswordReset,

	// Admin
	Home,
	Employees,
	Employee,
	Clients,
	Client,
	Tasks,
	Task,
	Services,
	Service,
	Documents,
	Document,

	// Client
	ClientHome,
	ClientProfile,
	ClientDocuments,
	ClientDocument,
	ClientServices,
	ClientService,

	// Accountant
	AccountantHome,
	AccountantClients,
	AccountantClient,
	AccountantTasks,
	AccountantTask,
	AccountantDocuments,
	AccountantDocument,
} from 'pages';

export const App = () => {
	const { auth } = useAuth();

	useEffect(() => {
		if (localStorage.getItem('i18nextLng')?.length > 2) {
			i18next.changeLanguage('en');
		}
	}, []);

	const renderRoutes = role => {
		if (role === 'admin') {
			return (
				<Route path="/" element={<Layout type="admin" />}>
					<Route path="/" element={<Home />} />
					<Route path="/employees" element={<Employees />} />
					<Route path="/employees/:id" element={<Employee />} />
					<Route path="/clients" element={<Clients />} />
					<Route path="/clients/:id" element={<Client />} />
					<Route path="/tasks" element={<Tasks />} />
					<Route path="/tasks/:id" element={<Task />} />
					<Route path="/services" element={<Services />} />
					<Route path="/services/:id" element={<Service />} />
					<Route path="/documents" element={<Documents />} />
					<Route path="/documents/:id" element={<Document />} />
				</Route>
			);
		} else if (role === 'client') {
			return (
				<Route path="/" element={<Layout type="client" />}>
					<Route path="/" element={<ClientHome />} />
					<Route path="/profile" element={<ClientProfile />} />
					<Route path="/documents" element={<ClientDocuments />} />
					<Route path="/documents/:id" element={<ClientDocument />} />
					<Route path="/services" element={<ClientServices />} />
					<Route path="/services/:id" element={<ClientService />} />
				</Route>
			);
		} else if (role === 'employee') {
			return (
				<Route path="/" element={<Layout type="accountant" />}>
					<Route path="/" element={<AccountantHome />} />
					<Route path="/clients" element={<AccountantClients />} />
					<Route path="/clients/:id" element={<AccountantClient />} />
					<Route path="/tasks" element={<AccountantTasks />} />
					<Route path="/tasks/:id" element={<AccountantTask />} />
					<Route path="/documents" element={<AccountantDocuments />} />
					<Route path="/documents/:id" element={<AccountantDocument />} />
				</Route>
			);
		} else {
			return <Route path="*" element={'Page not found'} />;
		}
	};

	return (
		<Routes>
			{/* Public routes */}
			<Route path="/login" element={<Login />} />
			{/* <Route path="/signup" element={<Signup />} /> */}
			<Route path="/password-forgot" element={<PasswordForgot />} />
			<Route path="/password-reset" element={<PasswordReset />} />

			{/* Logged in routes */}
			<Route element={<RequireLogin />}>{renderRoutes(auth?.user?.role)}</Route>

			<Route path="*" element={'Page not found'} />
		</Routes>
	);
};
