import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { LogoIcon } from 'assets/icons';
import BrandImage from './BrandImage';

const Container = styled(Grid)(({ theme }) => ({
	minHeight: '100vh',
	display: 'flex',
}));
const StyledGrid = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.base.background,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
const LogoContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
}));
const MobileLogoContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}));
const LogoTitle = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.base.black,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));
const Title = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.black002,
	fontSize: theme.typography.fontSize.h1,
	fontWeight: theme.typography.fontWeight.bold,
}));
const SubTitle = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.gray003,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));

const content = {
	login: {
		title: 'WelcomeBack',
		subtitle: 'ContinuePleaseFillYourData',
	},
	signup: {
		title: 'WelcomeOnBoard',
		subtitle: 'ContinuePleaseCreateNewPassword',
	},
	forgot: {
		title: 'ForgotPassword',
		subtitle: 'PleaseEnterYourEmailToReset',
	},
	reset: {
		title: 'NewPassword',
		subtitle: 'ContinuePleaseCreateNewPassword',
	},
};

export const LoginLayout = ({ type, children }) => {
	const { t } = useTranslation();

	const text = content[type];
	const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'));

	return (
		<Container container>
			<Grid item xs={12} md={6} padding={3}>
				{isTablet ? (
					<MobileLogoContainer sx={{ mt: 5, mb: 3 }}>
						<LogoTitle sx={{ mb: 3 }}>DINAMINĖ APSKAITA</LogoTitle>
						<BrandImage width="100" mobile={isTablet} />
					</MobileLogoContainer>
				) : (
					<LogoContainer sx={{ mb: 25 }}>
						<LogoIcon width="37" />
						<Box sx={{ ml: 1, mt: '4px' }}>
							<LogoTitle>DINAMINĖ APSKAITA</LogoTitle>
						</Box>
					</LogoContainer>
				)}
				<Grid container item alignItems="center" justifyContent="center">
					<Grid item xs={12} sm={7} md={7} lg={6}>
						<Title sx={{ textAlign: isTablet ? 'center' : 'left' }}>{t(text.title)}</Title>
						<SubTitle sx={{ textAlign: isTablet ? 'center' : 'left' }}>{t(text.subtitle)}</SubTitle>
						<Box sx={{ mt: 5 }}>{children}</Box>
					</Grid>
				</Grid>
			</Grid>
			{!isTablet && (
				<StyledGrid item md={6}>
					<BrandImage width="320" />
				</StyledGrid>
			)}
		</Container>
	);
};

LoginLayout.propTypes = {
	type: PropTypes.string.isRequired,
};
