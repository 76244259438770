import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { ChevronIcon } from 'assets/icons';

const StyledIcon = styled(ChevronIcon)(() => ({
	width: '10px',
	height: '6px',
}));

export const SearchSelect = ({ options, ...props }) => {
	return (
		<Autocomplete
			disablePortal
			disableClearable
			popupIcon={props.loading ? <CircularProgress color="inherit" size={20} /> : <StyledIcon />}
			options={options}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.id || option.label}>
						{option.label}
					</li>
				);
			}}
			renderInput={params => (
				<TextField
					{...params}
					label={props.label}
					placeholder={props.placeholder}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			)}
			{...props}
			onChange={props.onChange}
			value={props.value}
		/>
	);
};

SearchSelect.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
};
