import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Menu } from '@mui/material';

const StyledMenu = styled(Menu)(({ theme }) => ({
	'& .MuiPaper-root': {
		marginTop: theme.spacing(2),
		boxShadow: theme.shadow.s02,
		color: theme.palette.base.black001,
	},
}));
const ButtonWrapper = styled('div')(({ theme, open }) => ({
	button: {
		'boxShadow': 'none',
		'color': theme.palette.base.black,
		'fontSize': theme.typography.fontSize.base,
		'fontWeight': theme.typography.fontWeight.m,
		'.arrow': {
			color: theme.palette.base.black001,
			transition: '.2s',
			transform: open ? 'rotate(180deg)' : '',
		},
	},
}));

export const Dropdown = ({ button, menuContent, onClose, ...props }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setAnchorEl(null);
	}, [onClose]);

	return (
		<>
			<ButtonWrapper
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				open={open}
				{...props}
			>
				{button}
			</ButtonWrapper>

			<StyledMenu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{menuContent}
			</StyledMenu>
		</>
	);
};

Dropdown.propTypes = {
	button: PropTypes.node,
	menuContent: PropTypes.node,
	onClose: PropTypes.bool,
};
