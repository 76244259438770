import React from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { Input } from 'components';

const StyledEmailField = styled(Field)(({ theme }) => ({
	'.MuiInputBase-root': {
		border: 'none',
		background: theme.palette.base.background,
	},
	input: {
		'::placeholder': {
			fontWeight: theme.typography.fontWeight.bold,
		},
	},
}));

export const EmailField = ({ ...props }) => {
	const { t } = useTranslation();

	return (
		<StyledEmailField
			type="text"
			component={Input}
			placeholder={t('YourEmail')}
			InputLabelProps={{
				shrink: true,
				// required: true,
			}}
			fullWidth
			variant="standard"
			{...props}
		/>
	);
};
