import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { CompanyIcon, PhoneIcon, EmailIcon } from 'assets/icons';

const Container = styled('div')(({ theme }) => ({
	borderRadius: 8,
	padding: '24px 16px',
	backgroundColor: theme.palette.base.white001,
}));
const IconWrapper = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.gray002,
	width: 48,
	height: 48,
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.base,
	fontWeight: theme.typography.fontWeight.bold,
}));
const SubTitle = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
}));
const Text = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));

export const CompanyCard = ({ company = {}, onEdit }) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const CompanyDetailItem = ({ label, value }) => (
		<Stack direction="row" alignItems="center" sx={{ py: 0.5 }}>
			<Text sx={{ mr: 1 }}>{label}:</Text>
			<Text sx={{ fontWeight: 'regular' }}>{value}</Text>
		</Stack>
	);

	return (
		<>
			<Container>
				{/* <Stack direction="row" justifyContent="flex-end">
					<EditIcon onClick={onEdit} sx={{ color: theme.palette.gray003, cursor: 'pointer' }} />
				</Stack> */}
				<Stack direction="row" alignItems="center" sx={{ pb: 2, borderBottom: '1px solid #F8F8F8' }}>
					<IconWrapper>
						<CompanyIcon sx={{ fontSize: '25px' }} />
					</IconWrapper>
					<Stack sx={{ ml: 2, flex: 1 }}>
						<Title>{company.companyName}</Title>
						<SubTitle>{company.companyType || ''}</SubTitle>
					</Stack>
				</Stack>
				<Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
					<PhoneIcon sx={{ color: theme.palette.gray003, fontSize: '18px', mr: 1 }} />
					{company.phone}
				</Stack>
				<Stack direction="row" alignItems="center">
					<EmailIcon sx={{ color: theme.palette.gray003, fontSize: '18px', mr: 1 }} />
					{company.email}
				</Stack>
			</Container>
			<Container sx={{ mt: 1 }}>
				<Stack direction="row" alignItems="center" sx={{ pb: 1, borderBottom: '1px solid #DDDDDD' }}>
					<Text>{t('CompanyDetails')}</Text>
				</Stack>
				<CompanyDetailItem label={t('CompanyCode')} value={company.companyCode} />
				<CompanyDetailItem label={t('PVMCode')} value={company.PVMCode} />
				<CompanyDetailItem label={t('CompanyManager')} value={company.companyManager} />
				<CompanyDetailItem label={t('Address')} value={company.address} />
			</Container>
		</>
	);
};

CompanyCard.propTypes = {
	company: PropTypes.object.isRequired,
	onEdit: PropTypes.func,
};
