import * as React from 'react';
import { styled } from '@mui/system';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { Modal as ModalComponent, Stack, useMediaQuery } from '@mui/material';
import Fade from '@mui/material/Fade';
import { CloseIcon } from 'assets/icons';

const StyledBox = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxHeight: 'calc(100vh - 100px)',
	bgcolor: 'background.paper',
	background: '#FEFEFE',
	borderRadius: '8px',
	padding: '21px 32px 40px 32px',
	overflow: 'scroll',
	outline: 'none',
}));
const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.base,
	fontWeight: theme.typography.fontWeight.bold,
}));
const Header = styled(Stack)(({ theme }) => ({
	borderBottom: `1px solid ${theme.palette.gray002}`,
	justifyContent: 'space-between',
}));

export const Modal = ({ open, onClose, title, children, ...props }) => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

	return (
		<ModalComponent
			open={open}
			onClose={onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			sx={{
				backdropFilter: 'blur(4px)',
				'.MuiBackdrop-root': {
					backgroundColor: 'rgba(217, 217, 217, 0.59)',
				},
			}}
			{...props}>
			<Fade in={open}>
				<StyledBox sx={{ ...props.style, width: isMobile ? '90%' : props.width }}>
					<Header direction="row">
						<Title sx={{ mb: 1 }}>{title}</Title>
						<CloseIcon onClick={onClose} sx={{ fontSize: '14px', cursor: 'pointer' }} />
					</Header>
					{children}
				</StyledBox>
			</Fade>
		</ModalComponent>
	);
};
