import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { Select } from 'components';
import { STATE } from 'constants/general';

export const SelectChip = ({ options, field, optionLabel, optionValue }) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const color = {
		[STATE.NEW]: theme.palette.default.link,
		[STATE.ONGOING]: theme.palette.default.warning,
		[STATE.FINISHED]: theme.palette.default.success,
	};

	return (
		<Select
			placeholder={t('Select')}
			options={options}
			field={field}
			optionLabel={optionLabel}
			optionValue={optionValue}
			style={{
				'&:before': {
					fontSize: theme.typography.fontSize.small,
					fontWeight: theme.typography.fontWeight.bold,
					padding: '6px 14px 6px 16px',
				},
				'& .MuiInputBase-input': {
					// minWidth: '60px',
					borderRadius: 28,
					backgroundColor: '#F8F8F8',
					color: color[field.value],
					fontSize: theme.typography.fontSize.small,
					fontWeight: theme.typography.fontWeight.r,
					padding: '6px 14px 6px 16px',
					border: 'none',
					'&:focus': {
						borderRadius: 28,
					},
				},
			}}
		/>
	);
};

SelectChip.propTypes = {
	options: PropTypes.array.isRequired,
	field: PropTypes.object.isRequired,
	optionLabel: PropTypes.string,
	optionValue: PropTypes.string,
};
