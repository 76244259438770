import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { UploadIcon, CloseIcon } from 'assets/icons';
import { Stack, Box } from '@mui/material';

const Label = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.black002,
	fontWeight: theme.typography.fontWeight.bold,
	fontSize: theme.typography.fontSize.small,
}));
const Asterisk = styled('span')(({ theme }) => ({
	color: theme.palette.default.error,
	padding: '0 2px',
}));
const Text = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.r,
}));
const Container = styled('div')(({ theme, active }) => ({
	height: '120px',
	border: `1px solid ${theme.palette.gray003}`,
	borderRadius: '8px',
	color: active === 'true' ? theme.palette.black : theme.palette.gray003,
	backgroundColor: active === 'true' ? theme.palette.gold003 : '',
	cursor: 'pointer',
}));
const FilesList = styled(Box)(({ theme, active }) => ({
	display: 'flex',
	flexWrap: 'wrap',
}));
const FileTitle = styled('p')(({ theme, active }) => ({
	margin: 0,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
}));
const FileChip = styled('div')(({ theme }) => ({
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.r,
	backgroundColor: '#DDDDDD',
	border: 0,
	borderRadius: 8,
	padding: '10px 16px',
	maxWidth: '200px',
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
}));

export const InputUpload = ({ label, text, required, multiple = false, allowRemove = false, hideList = false, onChange, ...props }) => {
	const [files, setFiles] = useState([]);

	const handleRemove = idx => {
		const data = files.filter((_, index) => index !== idx);
		setFiles(data);
		onChange(multiple ? data : data[0]);
	};

	const onDrop = useCallback(
		acceptedFiles => {
			setFiles([...files, ...acceptedFiles]);
			onChange(multiple ? [...files, ...acceptedFiles] : acceptedFiles[0]);
		},
		[files, setFiles, onChange, multiple],
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple });
	return (
		<Box {...props}>
			<input {...getInputProps()} />
			{label && (
				<Label>
					{label}
					{required && <Asterisk>*</Asterisk>}
				</Label>
			)}
			<Container
				{...getRootProps()}
				active={isDragActive ? 'true' : 'false'}
				className="drop-area"
				sx={{ display: !multiple && files?.length > 0 ? 'none' : '' }}>
				<Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
					<UploadIcon sx={{ mr: 2 }} />
					<Text>{text}</Text>
				</Stack>
			</Container>
			{hideList ? null : (
				<FilesList sx={{ mt: 1 }}>
					{files.map((file, index) => (
						<FileChip key={index} sx={{ mr: 1, mt: 1 }} onClick={() => (allowRemove ? handleRemove(index) : null)}>
							<FileTitle>{file.name}</FileTitle>
							{allowRemove && <CloseIcon sx={{ ml: 1, fontSize: '10px' }} />}
						</FileChip>
					))}
				</FilesList>
			)}
		</Box>
	);
};
