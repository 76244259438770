import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Box, Divider, ButtonBase, useTheme } from '@mui/material';
import { SelectChip } from 'components';
import { EditIcon, UserIcon } from 'assets/icons';
import { STATE_OPTIONS, STATE } from 'constants/general';
import { defaultDateTimeFormat } from 'utils/utils';

const Container = styled('div')(({ theme }) => ({
	borderRadius: 8,
	padding: '24px 16px',
	backgroundColor: theme.palette.base.white001,
}));
const Title = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.base,
	fontWeight: theme.typography.fontWeight.bold,
}));
const TitleGray = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
	color: theme.palette.gray003,
}));
const Text = styled('p')(({ theme }) => ({
	margin: 0,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.r,
}));
const IconWrapper = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.gray002,
	width: 48,
	height: 48,
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

export const TaskCard = ({ task = {}, onEdit, onStateEdit }) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const state = task?.state;

	return (
		<>
			<Container>
				<Stack direction="row" justifyContent="flex-end" sx={{ height: '15px' }}>
					<ButtonBase onClick={onEdit} sx={{ padding: '5px', position: 'absolute' }}>
						<EditIcon sx={{ color: theme.palette.gray003, fontSize: '14px' }} />
					</ButtonBase>
				</Stack>
				<Grid container spacing={1}>
					<Grid item xs={9} md={4}>
						<Title>{task.name}</Title>
						<TitleGray>{task.clientName}</TitleGray>
					</Grid>
					<Grid item xs={3} md={2}>
						<SelectChip
							options={STATE_OPTIONS}
							field={{ value: state || STATE.NEW, onChange: e => onStateEdit(e.target.value) }}
						/>
					</Grid>
					<Grid item xs={9} md={4}>
						<Stack direction="row">
							<Box>
								<TitleGray>{t('StartTime')}</TitleGray>
								<Text>{defaultDateTimeFormat(task.startDate)}</Text>
							</Box>
							<Divider orientation="vertical" sx={{ height: 16, mx: 1, mt: '2px' }} />
							<Box>
								<TitleGray>{t('EndTime')}</TitleGray>
								<Text>{defaultDateTimeFormat(task.endDate)}</Text>
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={3} md={2}>
						<TitleGray>{t('TaskType')}</TitleGray>
						{'task.type'}
					</Grid>
				</Grid>
				{task.employees?.length > 0 &&
					task.employees.map((item, idx) => (
						<Box key={idx}>
							{idx === 0 && <Divider sx={{ pt: 3 }} />}
							<Stack direction="row" alignItems="center" sx={{ pt: 2 }}>
								<IconWrapper sx={{ width: 40, height: 40 }}>
									<UserIcon sx={{ fontSize: '21px' }} />
								</IconWrapper>
								<Stack sx={{ ml: 1, flex: 1 }}>
									<Title sx={{ fontSize: 14 }}>{item.name}</Title>
									<TitleGray>{item.position}</TitleGray>
								</Stack>
							</Stack>
						</Box>
					))}
			</Container>
		</>
	);
};

TaskCard.propTypes = {
	task: PropTypes.object.isRequired,
	onEdit: PropTypes.func,
	onStateEdit: PropTypes.func.isRequired,
};
