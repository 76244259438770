import React, { useState } from 'react';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { TextField, Box, Stack, MenuItem } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { generateTime } from 'utils/utils';
import 'dayjs/locale/lt';

const StyledInput = styled(TextField)(({ theme }) => ({
	'.MuiInputBase-root': {
		borderRadius: '8px',
		color: theme.palette.black002,
		fontSize: theme.typography.fontSize.small,
		border: `1px solid ${theme.palette.gray003}`,
		'&.Mui-error': {
			border: `1px solid ${theme.palette.default.error}`,
		},
		'input, .MuiSelect-select': {
			padding: '14px',
		},
		'.MuiInputAdornment-root': {
			svg: {
				fontSize: '20px !important',
			},
		},
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			border: 'none',
		},
	},
}));
const Asterisk = styled('span')(({ theme }) => ({
	color: theme.palette.default.error,
	padding: '0 2px',
}));
const InputLabel = styled('p')(({ theme }) => ({
	margin: 0,
	color: theme.palette.black,
	fontSize: theme.typography.fontSize.small,
	fontWeight: theme.typography.fontWeight.bold,
}));

export const InputDatePicker = ({
	label,
	value,
	initDate,
	onChange,
	startTime = 0,
	hideTime = false,
	shift,
	InputLabelProps = {},
	...props
}) => {
	const hours = generateTime({ from: 0, to: 1440, interval: 5, shift });
	const [date, setDate] = useState(moment(initDate));
	const [time, setTime] = useState(hours[startTime / 5 - (shift ? 1 : 0)].value);
	const { required } = InputLabelProps;

	return (
		<Box {...props}>
			{label && (
				<InputLabel>
					{label}
					{required && <Asterisk>*</Asterisk>}
				</InputLabel>
			)}
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="lt">
				<Stack direction="row" spacing={1}>
					<DatePicker
						views={['day']}
						value={date}
						onChange={newValue => {
							onChange({ date: newValue?.format('YYYY-MM-DD'), time: time });
							setDate(newValue);
						}}
						inputFormat="YYYY-MM-DD"
						minDate={moment()}
						renderInput={params => <StyledInput {...params} fullWidth />}
					/>
					{!hideTime && (
						<StyledInput
							select
							value={time}
							onChange={e => {
								onChange({ date: moment(date)?.format('YYYY-MM-DD'), time: e.target.value });
								setTime(e.target.value);
							}}
							sx={{ width: '130px' }}>
							{hours.map(option => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</StyledInput>
					)}
				</Stack>
			</LocalizationProvider>
		</Box>
	);
};
